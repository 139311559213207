<template>
  <Modal size="small" :name="name" title="Cupom de Desconto" ref="modal">
    <div class="d-flex jc-center">
      <div class="box-align w-100 mt-20">
        <CustomInput placeholder="Cupom de desconto" v-model="checkCupom" />

        <template v-if="errMsg">
          <small class="msg-err">Cupom inválido</small>
        </template>
        <div
          class="mt-20 mh-5"
          v-if="
            !(
              (paymentCouponDiscount && paymentCouponDiscount.percent) ||
              (presenceCouponDiscount && presenceCouponDiscount.percent)
            )
          ">
          <small>Cupons aplicados</small>
          <p class="msg-noCoupon">Nenhum cupom aplicado</p>
        </div>
        <div v-else>
          <span class="mv-100">Cupons aplicados</span>
          <div
            v-if="presenceCouponDiscount && presenceCouponDiscount.nameCoupon"
            class="coupon-item p-14 d-flex jc-space-between ai-center radius-10">
            <span>{{ presenceCouponDiscount.nameCoupon }}</span>
            <button @click="deleteCouponPresence" class="outline pv-0">
              <i class="icon-coupon text-white mdi mdi-close"></i>
            </button>
          </div>
          <div
            v-if="paymentCouponDiscount && paymentCouponDiscount.nameCoupon"
            class="coupon-item p-14 d-flex jc-space-between ai-center radius-10 mt-10">
            <span>{{ paymentCouponDiscount.nameCoupon }}</span>
            <button @click="deleteCouponPayment" class="outline pv-0">
              <i class="icon-coupon text-white mdi mdi-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div>
        <div class="d-flex m-15">
          <button class="btn outline fg-1" @click="close">
            <span>Cancelar</span>
          </button>
          <button @click="applyCoupon" class="btn primary fg-1">
            <span>Aplicar cupom</span>
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DiscountCupomModal",

  data: () => ({
    name: "DiscountCupomModal",
    openModal: false,
    errMsg: false,
  }),

  computed: {
    ...mapGetters({
      coupon: "Payment/coupon",
      responseIsValid: "Payment/responseIsValid",
      presenceCouponDiscount: "Payment/presenceCouponDiscount",
      paymentCouponDiscount: "Payment/paymentCouponDiscount",
    }),

    checkCupom: {
      get() {
        return this.coupon;
      },
      set(coupon) {
        this.$store.dispatch("Payment/coupon", coupon);
      },
    },
  },

  methods: {
    ...mapActions({
      validateCoupon: "Payment/validateCoupon",
      removeCouponPayment: "Payment/removeCouponPayment",
      removeCouponPresence: "Payment/removeCouponPresence",
      removeCoupon: "Payment/removeCoupon",
    }),

    close() {
      this.$refs.modal.close();
    },

    deleteCouponPresence() {
      this.removeCoupon();
      this.removeCouponPresence();
    },

    deleteCouponPayment() {
      this.removeCoupon();
      this.removeCouponPayment();
    },

    applyCoupon() {
      this.validateCoupon().then(() => {
        if (this.responseIsValid) {
          this.errMsg = false;
          this.close();
        } else {
          this.errMsg = !this.errMsg;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.fg-1 {
  flex-grow: 1;
}

.box-align {
  height: 125px;
  width: 400px;
  padding: 0rem 1rem;
}

.msg-err {
  color: red;
  padding-left: 6px;
  font-weight: 600;
}

.msg-noCoupon {
  color: $LightSilver;
}

.coupon-item {
  background: $Minsk;
}

.icon-coupon {
  width: 21px;
  height: 21px;
  border: solid 2px;
  border-radius: 50%;
}
</style>
