<template>
  <CustomAutocomplete
    class="search-hirer"
    :limit="3"
    id="search-hirer"
    :search="search"
    @selected="onHirerSelected"
    @empty="$emit('emptySelected')">
    <template #option="{ item: { name, cnpj, email } }">
      <div class="hirer-option d-flex ai-center w-100 p-6">
        <Avatar size="xsmall" />
        <div class="d-flex direction-column pl-10">
          <small class="text-azure-radiance">
            <b>{{ name }}</b>
            - {{ cnpj }}
          </small>
          <small class="text-half">{{ email }}</small>
        </div>
      </div>
    </template>

    <template #empty>
      <div class="hirer-option d-flex ai-center jc-space-between w-100 p-6">
        <small>Contratante não encontrado.</small>
        <button class="btn primary" @click="$emit('emptySelected')">
          <small>deseja cadastrar?</small>
        </button>
      </div>
    </template>
  </CustomAutocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { searchHirer } from "@/services/agency/agency.http";

export default {
  computed: {
    ...mapGetters({ agencyId: "User/userId" }),
  },
  methods: {
    search(term) {
      return searchHirer(this.agencyId, term);
    },
    onHirerSelected(hirer) {
      this.$emit("selected", hirer);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.search-hirer {
  li {
    .hirer-option {
      background: $Martinique;

      small {
        font-size: 0.75rem;

        &.text-half {
          font-size: 0.6rem;
        }
      }
    }

    &:hover {
      .hirer-option {
        background: transparentize($Martinique, 0.3);
      }
    }

    &.hover {
      .hirer-option {
        background: transparentize($Martinique, 0.45);
      }
    }

    &:last-child {
      .hirer-option {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
</style>
