<template>
  <section
    class="agency-item d-flex f-100 wrapped jc-space-between ai-center cursor-pointer">
    <article class="d-flex f-80 ai-center">
      <Avatar
        :src="agency.avatarUrl || require('@/assets/default-agency.png')"
        :alt="`logo da agência ${agency.name}`" />
      <div class="d-flex f-100 wrapped ml-10 as-start">
        <div class="d-flex f-100 title">
          <strong>{{ agency.name }}</strong>
        </div>
        <div class="d-flex f-100 wrapper">
          <span class="services">cnpj: {{ agency.cnpj }}</span>
        </div>
      </div>
    </article>
    <!-- <article class="d-flex f-20">
      <button disabled class="white-circle-button">Ver perfil</button>
    </article> -->
  </section>
</template>
<script>
export default {
  props: {
    agency: {
      type: Object,
      default: () => ({
        name: "",
        avatarUrl: "",
        services: [],
      }),
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.agency-item {
  background: $Martinique;
  border-radius: 10px;
  padding: 20px;

  .title {
    color: $CornflowerBlue;
    font-size: 20px;
    padding-bottom: 5px;
  }

  .services {
    font-size: 15px;
    font-weight: lighter;
    word-break: break-word;
  }
}
</style>
