<template>
  <Modal
    title="Recortar imagem"
    name="ModalCropper"
    ref="modal"
    @open="openModal"
    @close="close">
    <div class="h-100 p-50 radius-5">
      <div class="cropper-wrapper d-flex ai-center jc-center">
        <!-- <div
          :style="{ backgroundImage: 'url(' + image + ')' }"
          class="image-background"
        ></div> -->
        <Cropper :image="image" ref="cropper" />
      </div>
    </div>

    <template #footer>
      <div class="d-flex w-100 p-15 jc-end">
        <button class="btn outline" @click="close">Cancelar</button>
        <button class="btn primary" @click="_cropImg">Confirmar</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Cropper from "@/components/shared/cropper/Cropper.vue";

export default {
  name: "ModalCropper",

  components: {
    Cropper,
  },

  data() {
    return {
      image: "",
      cropImage: () => ({}),
      closeModal: () => ({}),
    };
  },

  methods: {
    openModal({ image, cropImage, closeModal }) {
      this.image = image;
      this.cropImage = cropImage;
      this.closeModal = closeModal;
    },
    _cropImg() {
      const result = this.$refs.cropper.$refs.cropper.getResult();
      this.cropImage(result.canvas.toDataURL());
    },

    close() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.cropper-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: 5px;
}
.image-background {
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  left: -10px;
  top: -10px;
  background-size: cover;
  background-position: 50%;
  filter: blur(5px);
}
</style>
