<template>
  <Modal
    :name="name"
    @open="onOpenModal"
    :title="title"
    size="full"
    ref="modal">
    <section class="d-flex f-100 h-100 flex-wrapped">
      <article class="d-flex f-20 flex-wrapped mr-20">
        <ul class="d-flex f-1 f-col">
          <li
            class="d-flex ai-center jc-space-between"
            v-for="(member, index) in filledSlots"
            :key="index">
            <Avatar :src="member.image" />
            <span class="ml-5">{{ member.description }}</span>
            <span>{{ distanceDestination(member) }}</span>
          </li>
        </ul>
      </article>
      <article class="d-flex f-80 h-100">
        <GoogleMapsDistanceToEvent
          class="d-flex f-100"
          :locations="teamLocation"
          :teamMembers="slots"
          :destination="basicInfo.destination"></GoogleMapsDistanceToEvent>
      </article>
    </section>
  </Modal>
</template>

<script>
import GoogleMapsDistanceToEvent from "@/components/events/Google/GoogleMapsDistanceToEvent.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    GoogleMapsDistanceToEvent,
  },

  computed: {
    ...mapGetters({
      slots: "EventDetails/currentSlots",
      teamLocation: "EventDetails/actualTeamLocalization",
      basicInfo: "EventDetails/basicInfo",
    }),

    filledSlots() {
      return this.teamLocation
        ? this.slots.filter(({ freelaId, day }) =>
            this.teamLocation.find(
              (t) => t.freelaId == freelaId && this.compareDate(day.raw)
            )
          )
        : [];
    },
  },
  data: () => ({
    name: "freela-geolocation",
    title: "localização",
    teamMembers: [],
    eventId: "",
    referenceDate: "",
  }),

  methods: {
    ...mapActions({
      getLocations: "EventDetails/getServiceTeamLocalization",
      getEventLocalization: "EventDetails/getEventLocalization",
    }),
    onOpenModal($event) {
      const freelas = $event.slots.map((s) => ({
        name: s.description,
        id: s.freelaId,
        image: s.image,
      }));
      const freelasId = freelas.map((f) => f.id);
      const date = $event.referenceDate.toISOString();
      this.referenceDate = date;
      this.eventId = $event.eventId;
      this.getLocations({
        eventId: this.eventId,
        freelas: freelasId,
        referenceDate: date,
      });
      this.getEventLocalization(this.basicInfo.addressId);
    },
    compareDate(date) {
      if (
        date === null ||
        date === undefined ||
        this.referenceDate === null ||
        this.referenceDate === undefined
      )
        return true;
      const isEqual = date?.split("T")[0] === this.referenceDate?.split("T")[0];
      return isEqual;
    },
    distanceDestination({ freelaId }) {
      if (this.teamLocation.length && this.basicInfo.destination) {
        const { latLng } = this.teamLocation.find(
          (l) => l.freelaId === freelaId
        );

        const distanceInMeters =
          // eslint-disable-next-line no-undef
          google.maps.geometry.spherical.computeDistanceBetween(
            // eslint-disable-next-line no-undef
            new google.maps.LatLng({ ...latLng }),
            // eslint-disable-next-line no-undef
            new google.maps.LatLng({ ...this.basicInfo.destination.latLng })
          );

        const distanceInKms = (distanceInMeters / 1000).toFixed(1);

        return `${distanceInKms}/km`;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";

li {
  height: 65px;
  padding: 0 5px 0 5px;

  &:hover {
    background: transparentize($Martinique, 0.3);
  }
}
</style>
