<template>
  <section class="agency-container">
    <template v-if="!isExclusive">
      <p>
        Escolha até
        <b>05</b>
        empresas para receber orçamento:
      </p>
      <CustomAutocomplete
        class="mb-10"
        limit="5"
        placeholder="Digite para buscar a empresa"
        :search="search"
        @selected="onAgencySelected">
        <template #option="{ item: { name, cnpj } }">
          <div class="agency-option d-flex ai-center w-100 p-6">
            <Avatar size="xsmall" />
            <div class="d-flex direction-column pl-10">
              <small class="text-azure-radiance">
                <b>{{ name }}</b>
                - {{ cnpj }}
              </small>
            </div>
          </div>
        </template>

        <template #empty>Nenhuma empresa encontrada</template>
      </CustomAutocomplete>

      <Divider theme="martinique" class="mb-20" />
    </template>

    <article v-for="(agency, index) in agencies" :key="index">
      <AgencyItem class="mb-10" v-if="agency.id" :agency="agency" />
    </article>
  </section>
</template>

<script>
import AgencyItem from "./AgencyItem.vue";
import { searchAgency } from "@/services/agency/agency.http";

export default {
  name: "AgencyContainer",
  components: { AgencyItem },
  props: {
    agencies: {
      type: Array,
      default: () => [],
    },
    isExclusive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    search(term) {
      return searchAgency(term);
    },
    onAgencySelected(agency) {
      this.$emit("input", [...this.agencies, agency]);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.agency-container {
  ul {
    // background: $Martinique;
    border-radius: 10px;
    li {
      .agency-option {
        background: $Martinique;

        small {
          font-size: 0.75rem;

          &.text-half {
            font-size: 0.6rem;
          }
        }
      }

      &:hover {
        .agency-option {
          background: transparentize($Martinique, 0.3);
        }
      }

      &.hover {
        .agency-option {
          background: transparentize($Martinique, 0.45);
        }
      }

      &:last-child {
        .hirer-option {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }
}
</style>
