var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"modal",attrs:{"name":_vm.name,"title":"Correção de data e hora"},on:{"open":_vm.onModalOpen},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_c('small',[_vm._v("Correção de hora e data")])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex fg-1"}),_c('div',{staticClass:"d-flex m-15"},[_c('button',{staticClass:"btn outline"},[_c('span',{staticClass:"ph-30",on:{"click":_vm.close}},[_vm._v("Cancelar")])]),_c('button',{staticClass:"btn primary",on:{"click":_vm.editSchedule}},[_c('span',{staticClass:"ph-40"},[_vm._v("Salvar")])])])])]},proxy:true}])},[_c('section',{staticClass:"pv-15 ph-20"},[_c('div',{staticClass:"entrance ai-center"},[_c('h3',{staticClass:"ph-15 pt-15"},[_vm._v("Entrada")]),_c('Divider'),_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"ph-15 pt-10"},[_vm._v("Planejado")]),_c('small',{staticClass:"ph-15 pt-10"},[_vm._v(_vm._s(_vm._f("formatData")(_vm.checkin.planned)))])]),_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"ph-15 pt-10"},[_vm._v("Realizado")]),_c('small',{staticClass:"ph-15 pt-10"},[_vm._v(_vm._s(_vm._f("formatData")(_vm.checkin.done)))])]),_c('div',{staticClass:"d-flex ai-center"},[_c('small',{staticClass:"ph-15 pv-10"},[_vm._v("Correção:")]),_c('div',{staticClass:"d-flex mv-10"},[_c('v-date-picker',{staticClass:"d-flex f-30",attrs:{"mode":"date","is24hr":"","is-dark":"","is-inline":"","popover":{
              placement: _vm.$screens({ sm: 'auto' }),
              visibility: 'click',
            },"update-on-input":false},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkinDate),callback:function ($$v) {_vm.checkinDate=$$v},expression:"checkinDate"}}),_c('v-date-picker',{staticClass:"d-flex f-30",attrs:{"mode":"time","is24hr":"","is-dark":"","is-inline":"","popover":{
              placement: 'bottom',
              visibility: 'click',
            },"update-on-input":false},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkinTime),callback:function ($$v) {_vm.checkinTime=$$v},expression:"checkinTime"}})],1)])],1),_c('div',{staticClass:"entrance ai-center"},[_c('h3',{staticClass:"ph-15 pt-15"},[_vm._v("Saida")]),_c('Divider'),_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"ph-15 pt-10"},[_vm._v("Planejado")]),_c('small',{staticClass:"ph-15 pt-10"},[_vm._v(_vm._s(_vm._f("formatData")(_vm.checkout.planned)))])]),_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"ph-15 pt-10"},[_vm._v("Realizado")]),_c('small',{staticClass:"ph-15 pt-10"},[_vm._v(_vm._s(_vm._f("formatData")(_vm.checkout.planned)))])]),_c('div',{staticClass:"d-flex ai-center"},[_c('small',{staticClass:"ph-15 pv-10"},[_vm._v("Correção:")]),_c('div',{staticClass:"d-flex mv-10"},[_c('v-date-picker',{staticClass:"d-flex f-30",attrs:{"mode":"date","is24hr":"","is-dark":"","is-inline":"","popover":{
              placement: _vm.$screens({ sm: 'auto' }),
              visibility: 'click',
            },"update-on-input":false},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkoutDate),callback:function ($$v) {_vm.checkoutDate=$$v},expression:"checkoutDate"}}),_c('v-date-picker',{staticClass:"d-flex f-30",attrs:{"mode":"time","is24hr":"","is-dark":"","is-inline":"","popover":{
              placement: 'bottom',
              visibility: 'click',
            },"update-on-input":false},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkoutTime),callback:function ($$v) {_vm.checkoutTime=$$v},expression:"checkoutTime"}})],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }