<template>
  <div class="mb-10">
    <section class="d-flex f-100 jc-space-between ai-end pl-5 pr-5">
      <v-date-picker
        v-model="checkinDate"
        mode="date"
        is24hr
        is-dark
        is-inline
        class="d-flex f-30"
        :class="{ 'disabled-container': isEdit && currentServiceIsPrev }"
        :popover="{
          placement: $screens({ sm: 'auto' }),
          visibility: 'click',
        }"
        :update-on-input="false"
        :available-dates="{ start: value.date, end: value.date }">
        <template v-slot="{ inputValue, inputEvents }">
          <CustomInput
            placeholder="Entrada"
            :value="inputValue"
            v-on="inputEvents"
            :readonly="true"></CustomInput>
        </template>
      </v-date-picker>

      <v-date-picker
        v-model="checkinTime"
        mode="time"
        is24hr
        :minute-increment="10"
        is-dark
        is-inline
        class="d-flex f-20"
        :popover="{
          placement: 'bottom',
          visibility: 'click',
        }"
        :update-on-input="false">
        <template v-slot="{ inputValue, inputEvents }">
          <CustomInput
            :value="inputValue"
            v-on="inputEvents"
            :readonly="true"></CustomInput>
        </template>
      </v-date-picker>

      <v-date-picker
        v-model="checkoutDate"
        mode="date"
        is-dark
        is-inline
        class="d-flex f-30"
        :class="{ 'disabled-container': isEdit && currentServiceIsPrev }"
        :popover="{
          placement: $screens({ sm: 'auto' }),
          visibility: 'click',
        }"
        :update-on-input="false"
        :available-dates="{ start: value.date, end: null }">
        <template v-slot="{ inputValue, inputEvents }">
          <CustomInput
            placeholder="Saída"
            :value="inputValue"
            v-on="inputEvents"
            :readonly="true"></CustomInput>
        </template>
      </v-date-picker>

      <v-date-picker
        v-model="checkoutTime"
        mode="time"
        is24hr
        :minute-increment="10"
        is-dark
        class="d-flex f-20"
        is-inline
        :popover="{
          placement: 'bottom',
          visibility: 'click',
        }"
        :update-on-input="false">
        <template v-slot="{ inputValue, inputEvents }">
          <CustomInput
            :value="inputValue"
            v-on="inputEvents"
            :readonly="true"></CustomInput>
        </template>
      </v-date-picker>

      <CustomInput
        placeholder="Quantidade"
        class="d-flex f-10"
        :value="value.quantity"
        @input="$emit('input', { ...value, quantity: $event })"></CustomInput>
      <i
        class="mdi mdi-minus-circle-outline cursor-pointer mt-15"
        @click.prevent="$emit('remove')" />
    </section>
    <md-checkbox
      v-if="workshiftCardIndex === 0 && workshiftIndex === 0"
      class="faq-check-item m-8"
      v-model="canReplicate">
      Aplicar a mesma hora de entrada e saída para todos os dias
    </md-checkbox>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import CustomInput from "../../../shared/form/CustomInput.vue";

export default {
  components: { CustomInput },
  name: "Workshift",
  data() {
    return {
      canReplicate: false,
    };
  },
  props: {
    workshiftCardIndex: {
      type: Number,
      default: 0,
    },

    workshiftIndex: {
      type: Number,
      default: 0,
    },

    service: {
      type: Object,
      default: () => {},
    },

    value: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    canReplicate(value) {
      if (value) {
        const { checkinTime, checkoutTime } = this.value;
        this.$eventhub.$emit("replicateHours", { checkinTime, checkoutTime });
      } else {
        this.$eventhub.$emit("replicateHours", {
          checkinTime: "",
          checkoutTime: "",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      isEdit: "EditEvent/isEdit",
      previouslySelected: "EditEvent/previouslySelected",
    }),

    currentServiceIsPrev() {
      return this.previouslySelected.includes(this.service.job);
    },

    checkinDate: {
      get() {
        return this.value.checkinDate ?? null;
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          checkinDate: value,
        });
      },
    },
    checkoutDate: {
      get() {
        return this.value.checkoutDate ?? null;
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          checkoutDate: value,
        });
      },
    },
    checkinTime: {
      get() {
        return this.value.checkinTime ?? null;
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          checkinTime: value,
        });
      },
    },
    checkoutTime: {
      get() {
        return this.value.checkoutTime ?? null;
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          checkoutTime: value,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  font-size: 24px;
}
</style>
