<template>
  <Modal
    class="service-list-modal"
    :name="name"
    @open="onModalOpen"
    title="Adicionar Funções"
    ref="modal">
    <template>
      <div class="p-15">
        <h5 class="title mb-0">Funções cadastradas atualmente:</h5>
        <div class="mb-10">
          <small v-if="isAgency" class="text-white">
            <i class="mdi mdi-star"></i>
            - seus serviços exclusivos
          </small>
        </div>
        <ServiceList
          @onSelected="selectedServices = $event"
          :selectedServices="selectedServices"
          :exclusiveServices="exclusiveServices" />
      </div>
    </template>

    <template #footer>
      <div class="d-flex f-1 jc-end ai-end wrapped footer p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <button
          class="btn primary"
          @click.prevent="addServiceItems(selectedServices)">
          Adicionar
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
import ServiceList from "@/components/shared/ServiceList.vue";
import { isAgency } from "@/helpers/auth";
import { mapGetters } from "vuex";

export default {
  components: { ServiceList },

  data: () => ({
    name: "services",
    selectedServices: [],
    exclusiveServices: {},
    onAdd: () => ({}),
  }),

  computed: {
    ...mapGetters({
      isEdit: "EditEvent/isEdit",
      previouslySelected: "EditEvent/previouslySelected",
    }),

    isAgency() {
      return isAgency();
    },
  },

  methods: {
    close() {
      this.$refs.modal.close();
    },

    inputHandler($event, item) {
      this.services[item] = $event;
    },

    addServiceItems(selected) {
      const filteredServices = selected.filter((service) => {
        return !this.previouslySelected.includes(service);
      });
      this.close();
      this.onAdd(filteredServices);
    },

    onModalOpen($event) {
      const { servicesAdded, exclusiveServices, onAdd } = $event;

      this.selectedServices = servicesAdded;
      this.exclusiveServices = exclusiveServices;
      this.onAdd = onAdd;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.service-list-modal {
  h5.title {
    font-size: initial;
    font-weight: 400;
    margin: 0 0 1rem;
  }

  .body.md-theme-default {
    margin-top: 2px;
    overflow: auto;

    .md-list.md-theme-default {
      width: 90%;
    }
  }
}
</style>
