<template>
  <Modal
    class="service-list-modal"
    :name="name"
    @open="onModalOpen"
    title="Adicional"
    ref="modal"
    size="mini">
    <section class="w-100 f-100 d-flex direction-column ai-space-between p-25">
      <span class="font-size-1 mb-16">Valor adicional</span>

      <CustomInput
        v-model="additionalValue"
        type="currency"
        class="d-flex f-100 pb-5 additional-input" />

      <div class="w-100 d-flex ai-center p-relative checkbox-container">
        <CustomCheckbox
          id="additional-payment"
          type="checkbox"
          :item="service"
          :checked="chargeHirer"
          @change="onChange" />

        <label for="additional-payment" class="ml-8">
          Permitir cobrança do contratante
        </label>
      </div>
    </section>

    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <SpinnerButton
          :loading="loading"
          class="btn primary"
          @click="saveAdditionalPayment">
          Salvar adicional
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import { strCurrencyToDecimal } from "@/helpers/form/currencyConvert";

export default {
  data: () => ({
    name: "additional-payment",
    title: "Adicional",
    vacancyId: "",
    additionalValue: "",
    chargeHirer: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      service: "EventDetails/currentService",
      root: "Payment/membersWithJobDone",
      child: "Payment/child",
    }),
  },
  methods: {
    close() {
      this.$refs.modal.close();
      this.additionalValue = 0;
      this.chargeHirer = false;
    },

    onModalOpen(vacancyId) {
      this.vacancyId = vacancyId;
      const child = this.child(this.vacancyId);

      this.additionalValue = child.paymentService.find(
        (p) => p.vacancyId === this.vacancyId
      ).additionalValue;
    },

    onChange(node, checked) {
      this.chargeHirer = checked;
    },

    saveAdditionalPayment() {
      this.$store.dispatch("EventDetails/updateChargeHirer", this.chargeHirer);
      this.$store.dispatch("Payment/updateAdditionalValue", {
        additionalValue: strCurrencyToDecimal(this.additionalValue),
        chargeHirer: this.chargeHirer,
        vacancyId: this.vacancyId,
      });
      this.$refs.modal.close();
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.additional-input {
  min-width: 30rem;
}
</style>
