<template>
  <section class="d-flex f-100 wrapped jc-center">
    <Checklist
      title="Liste a jornada operacional dos profissionais:"
      :list="service.responsabilities"
      @input="$emit('input', $event)" />
  </section>
</template>
<script>
import Checklist from "@/components/shared/form/Checklist.vue";
export default {
  name: "Responsability",
  components: {
    Checklist,
  },
  props: {
    service: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
