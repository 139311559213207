<template>
  <Modal
    class="hirer-modal"
    :name="name"
    @open="onModalOpen"
    title="Adicionar Cliente"
    ref="modal">
    <template>
      <div v-if="!showFormHirer" class="pl-25 pr-25 pt-20">
        <h5 class="title">Sou o responsável pela demanda</h5>
        <div
          :class="{ active: getHirer.cnpj === identification.doc }"
          class="hirer-option-default d-flex ai-center w-100 p-10 mt-5"
          id="my-agency">
          <Avatar size="xsmall" />
          <div
            @click="
              onHirerSelected({
                cnpj: identification.doc,
                email: identification.email,
                name: identification.fantasyName,
                services: identification.services,
              })
            "
            class="d-flex direction-column pl-10">
            <small class="text-azure-radiance">
              {{ identification.fantasyName }} - {{ identification.doc }}
            </small>
            <small class="text-half">{{ identification.email }}</small>
          </div>
        </div>
      </div>
      <RegisterHirerForm v-if="showFormHirer" v-model="hirer" class="w-100" />
      <div v-else class="pl-25 pr-25 pt-20">
        <h5 class="title pb-5">Outro responsável (contratante)</h5>
        <SearchHirer
          id="another-responsible"
          class="mt-1"
          @selected="onHirerSelected"
          @emptySelected="onSearchHirerEmptySelected" />
      </div>
    </template>

    <template #footer>
      <div class="d-flex f-1 jc-end ai-end wrapped footer p-20">
        <button id="btn-cancel" class="btn outline" @click="close">
          Cancelar
        </button>
        <SpinnerButton
          class="btn primary"
          id="btn-add"
          :loading="loading"
          :disabled="hasPending"
          @click="onAddClick">
          Adicionar
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapGetters } from "vuex";
import { not } from "ramda";
import { debounce } from "lodash";

import SearchHirer from "./SearchHirer";
import RegisterHirerForm from "./RegisterHirerForm";
import { saveHirer } from "@/services/agency/agency.http";

export default {
  components: { SearchHirer, RegisterHirerForm },
  data: () => ({
    name: "hirer",
    loading: false,
    showFormHirer: false,
    selectHirer: () => ({}),
    hirer: {
      id: undefined,
      name: "",
      cnpj: "",
      phone: "",
      email: "",
      services: [],
    },
  }),
  computed: {
    ...mapGetters({
      agencyId: "User/userId",
      identification: "Profile/identification",
      getHirer: "NewEvent/hirer",
    }),
    hasPending() {
      const { name, cnpj, phone, email, services } = this.hirer;

      return (
        not(name) ||
        not(cnpj) ||
        not(phone) ||
        not(email) ||
        not(services.length)
      );
    },
  },

  methods: {
    close() {
      this.$refs.modal.close();
    },

    onModalOpen($event) {
      const { onSelectHirer = () => ({}) } = $event;
      this.showFormHirer = false;
      this.selectHirer = onSelectHirer;
    },

    onHirerSelected(hirer) {
      this.sendSelectedHirer(hirer);
    },
    onAddClick() {
      this.loading = true;
      debounce(
        () =>
          saveHirer(this.agencyId, this.hirer)
            .then(() => {
              this.sendSelectedHirer(this.hirer);
              this.notify("Cliente cadastrado com sucesso", "success");
            })
            .catch((err) => {
              this.notify(
                err.response.data.errorMessage.split("\n").join("<br>")
              );
            })
            .finally(() => (this.loading = false)),
        500
      )();
    },
    sendSelectedHirer(hirer) {
      this.selectHirer(hirer);
      this.close();
    },

    onSearchHirerEmptySelected() {
      this.showFormHirer = true;
    },
    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", {
        type,
        msg,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
.hirer-option-default {
  background: $Martinique;
  border: 2px solid $EastBay;
  border-radius: 5px;
  &:hover {
    border-color: $LilacBush;
    cursor: pointer;
  }
  &.active {
    border-color: $LilacBush;
  }
  small {
    font-size: 1em;

    &.text-half {
      font-size: 0.6rem;
    }
  }
}
.hirer-modal {
  h5.title {
    font-size: 1em;
    font-weight: 400;
    margin: 0 0 1rem;
    display: initial;
  }

  header {
    background: $Martinique;
    border-radius: 8px 8px 0 0;
    padding: 0 1rem;
    height: 50px;
    width: 100%;

    h2 {
      color: white;
      font-size: 18px;
    }
  }
}
</style>
