<template>
  <Modal
    class="create-group-modal"
    name="createGroup"
    @open="onModalOpen"
    :title="labels.title"
    ref="modal">
    <template>
      <Tabs :tabs="tabs">
        <Tab tab-id="Geral" class="p-20">
          <CustomInput
            v-model="workgroup.name"
            placeholder="Digite o nome do grupo"
            label="Digite o nome do grupo" />

          <div class="pt-20 pl-10">
            <span>Capa</span>
            <ImageField
              class="pt-10"
              @onChange="onImageChange"
              :preview="workgroup.preview" />
          </div>
        </Tab>

        <Tab tab-id="Membros" class="p-20">
          <SearchMember
            @selected="onMemberSelected"
            @removed="onRemoveButtonClick"
            v-model="input"
            :members="workgroup.freelas"></SearchMember>
        </Tab>
      </Tabs>
    </template>

    <template #footer>
      <div class="d-flex f-1 jc-end ai-end footer p-15">
        <SpinnerButton
          class="btn primary"
          :loading="loading"
          @click="onButtonClick(workgroup)">
          {{ labels.submit }}
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>
<script>
import {
  saveWorkgroup,
  searchMembers,
  getWorkgroupById,
} from "@/services/agency/agency.http";

import SearchMember from "@/components/shared/SearchMember.vue";
export default {
  components: {
    SearchMember,
  },
  data: () => ({
    name: "createGroup",
    loading: false,
    input: "",
    onCreatedCallback: () => ({}),
    workgroup: {
      id: undefined,
      name: "",
      image: "",
      freelas: [],
    },
    tabs: [
      { id: "Geral", label: "Geral" },
      { id: "Membros", label: "Membros" },
    ],
  }),

  computed: {
    labels() {
      const create = { submit: "Criar", title: "Criar Grupo" };
      const update = {
        submit: "Salvar",
        title: `Atualizar Grupo "${this.workgroup.name}"`,
      };

      return this.workgroup.id ? update : create;
    },
  },

  methods: {
    close() {
      this.$refs.modal.close();
      this.clean();
    },

    clean() {
      this.membersInput = "";
      this.workgroup = {
        name: "",
        freelas: [],
        image: "",
      };
    },

    search(term) {
      return searchMembers(term).then(({ content }) => content);
    },

    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", { msg, type });
    },

    saveWorkgroup(workgroup) {
      return saveWorkgroup(workgroup).then(({ value: id }) => {
        if (id) {
          workgroup.id = id;
        }
        this.onCreatedCallback(workgroup);
      });
    },

    onModalOpen($event) {
      const { onCreated = () => ({}), group = {} } = $event;

      this.onCreatedCallback = onCreated;
      this.clean();

      if (group.id) {
        getWorkgroupById(group.id).then((workgroup) => {
          this.workgroup.id = group.id;
          this.workgroup.preview = workgroup.thumb;
          this.workgroup.name = workgroup.name;
          this.workgroup.freelas = workgroup.members;
        });
      }
    },

    onImageChange({ imageBase64 }) {
      this.workgroup.image = imageBase64;
    },

    onButtonClick({ id, name, image, freelas }) {
      const successMessage = id
        ? `Grupo "${name}" Atualizado!`
        : `Grupo "${name}" criado com "${freelas.length}" profissionais`;

      const notifySuccess = () => this.notify(successMessage, "success");
      const notifyError = (err) => this.notify(err.response.data.errorMessage);

      this.loading = true;

      const workgroup = {
        id,
        name,
        image,
        freelaIds: freelas.map(({ memberId }) => memberId),
      };

      this.saveWorkgroup(workgroup)
        .then(notifySuccess)
        .then(this.clean)
        .then(this.close)
        .catch(notifyError)
        .finally(() => (this.loading = false));
    },

    onMemberSelected(member) {
      this.input = "";
      this.workgroup.freelas.push(member);
    },

    onRemoveButtonClick(id) {
      this.workgroup.freelas = this.workgroup.freelas.filter(
        ({ memberId }) => memberId != id
      );
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.create-group-modal {
  .tabs-component .tab-header {
    background: $Martinique;

    li {
      border-radius: 0;

      &.active {
        color: $FuchsiaBlue;
        background-image: linear-gradient($FuchsiaBlue, $FuchsiaBlue);
      }
    }
  }

  .selected-member {
    background: $Martinique;
  }
}
</style>
