<template>
  <Modal
    class="service-list-modal"
    :name="name"
    @open="onModalOpen"
    :title="title"
    ref="modal"
    size="mini">
    <section class="p-40">
      <span class="mb-35 font-size-1">
        Você tem certeza que deseja cancelar o lote
        <span class="text-malibu">{{ batchNumber }}</span>
        ?
      </span>
    </section>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <SpinnerButton
          :loading="loading"
          class="btn primary"
          @click="cancellationBatch">
          Confirmar
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    name: "batch-cancellation-confirmation",
    title: "Confirmar cancelamento",
    totalSelected: 0,
    loading: false,
    batchId: "",
    batchNumber: "",
  }),
  computed: {
    ...mapGetters({
      basicInfo: "EventDetails/basicInfo",
    }),
  },
  methods: {
    ...mapActions({
      cancelBatch: "Payment/cancelBatch",
    }),
    close() {
      this.$refs.modal.close();
    },

    onModalOpen($event) {
      this.batchId = $event.batchId;
      this.batchNumber = $event.batchNumber;
    },

    cancellationBatch() {
      this.loading = true;
      this.cancelBatch({
        id: this.batchId,
        batchNumber: this.batchNumber,
      }).finally(() => {
        this.loading = false;
        this.$refs.modal.close();
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
</style>
