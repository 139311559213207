<template>
  <PageLayout>
    <template slot="header">
      <TopBar />
    </template>

    <template slot="content">
      <div class="h-100">
        <md-content class="md-scrollbar h-100">
          <div class="h-100">
            <router-view />
          </div>
        </md-content>
      </div>
    </template>

    <template slot="footer">
      <EditEventServiceModal />
      <FAQModal />
      <ServiceListModal />
      <MemberAvaliationModal />
      <CorrectSchedulesModal />
      <DiscountCupomModal />
      <AvaliationModal />
      <VacancyValueModal />
      <OccurrenceLinkModal />
      <FreelaProfileModal />
      <PromotionCodeModal />
      <RegisterHirerModal />
      <InactiveAgencyAlertModal />
      <CreateGroupModal />
      <UserGroupsModal />
      <FreelaGeolocationModal />
      <MemberModal />
      <ReplicationModal />
      <EmergencialVacancyModal />
      <ConfirmationPaymentModal />
      <AdditionalPaymentModal />
      <BatchCancellationConfirmationModal />
      <ModalCropper />
      <FilterPayroll />
    </template>
  </PageLayout>
</template>

<script>
import { not } from "ramda";
import { mapActions, mapGetters } from "vuex";
import { isAuthenticated, isAgency } from "@/helpers/auth/";

import TopBar from "@/components/main/TopBar";
import EditEventServiceModal from "@/components/events/StepperApp/Modal/EditEventServiceModal";
import FAQModal from "@/components/events/StepperApp/Modal/FaqListModal";
import RegisterHirerModal from "@/components/events/StepperApp/Modal/hirer/RegisterHirerModal";
import OccurrenceLinkModal from "@/components/operation/OccurrenceLinkModal";
import ServiceListModal from "@/components/events/StepperApp/Modal/ServiceListModal";
import FreelaProfileModal from "@/components/events/EventInfo/EventServices/Modal/FreelaProfileModal.vue";
import PromotionCodeModal from "@/components/events/StepperApp/Modal/PromotionCodeModal";
import MemberAvaliationModal from "@/components/events/EventInfo/EventServices/Modal/MemberAvaliationModal.vue";
import CorrectSchedulesModal from "@/components/events/EventInfo/EventServices/Modal/CorrectSchedulesModal.vue";
import DiscountCupomModal from "@/components/events/EventInfo/EventPayroll/DiscountCupomModal.vue";
import AvaliationModal from "@/components/events/EventInfo/Negotiation/Modal/AvaliationModal.vue";
import VacancyValueModal from "@/components/events/EventInfo/EventServices/Modal/VacancyValueModal.vue";
import InactiveAgencyAlertModal from "@/components/profile/InactiveAgencyAlertModal.vue";
import CreateGroupModal from "@/components/freelas/CreateGroupModal.vue";
import UserGroupsModal from "@/components/freelas/UserGroupsModal.vue";
import FreelaGeolocationModal from "@/components/events/EventInfo/EventServices/Modal/FreelaGeolocationModal.vue";
import MemberModal from "@/components/events/EventInfo/EventServices/Modal/MemberModal.vue";
import ReplicationModal from "@/components/events/myevents/ReplicationModal.vue";
import EmergencialVacancyModal from "@/components/operation/EmergencialVacancyModal.vue";
import ConfirmationPaymentModal from "@/components/events/EventInfo/EventPayroll/ConfirmationPaymentModal.vue";
import AdditionalPaymentModal from "@/components/events/EventInfo/EventPayroll/AdditionalPaymentModal.vue";
import BatchCancellationConfirmationModal from "@/components/events/EventInfo/EventPayroll/Batch/BatchCancellationConfirmationModal.vue";
import ModalCropper from "@/components/shared/form/ModalCropper.vue";
import FilterPayroll from "@/components/shared/FilterPayroll.vue";

export default {
  components: {
    TopBar,
    EditEventServiceModal,
    FAQModal,
    RegisterHirerModal,
    ServiceListModal,
    MemberAvaliationModal,
    CorrectSchedulesModal,
    DiscountCupomModal,
    AvaliationModal,
    VacancyValueModal,
    OccurrenceLinkModal,
    FreelaProfileModal,
    PromotionCodeModal,
    InactiveAgencyAlertModal,
    CreateGroupModal,
    UserGroupsModal,
    FreelaGeolocationModal,
    MemberModal,
    ReplicationModal,
    EmergencialVacancyModal,
    ConfirmationPaymentModal,
    AdditionalPaymentModal,
    BatchCancellationConfirmationModal,
    ModalCropper,
    FilterPayroll,
  },
  data: () => ({
    menuVisible: false,
    isModalVisible: false,
  }),
  computed: mapGetters({
    isActiveAccount: "Profile/isActiveAccount",
  }),
  mounted() {
    if (isAuthenticated() && isAgency()) {
      this.loadProfile().then(this.validateActiveAgency);
    }
    if (isAuthenticated() && !isAgency()) {
      this.getInfoBasicHirer();
    }
    if (this.$route.name === "view-profile") {
      this.$eventhub.$emit("modal:open:freela-profile-modal");
    }
  },
  methods: {
    ...mapActions({
      loadProfile: "Profile/init",
      getInfoBasicHirer: "Profile/getInfoBasicHirer",
    }),
    validateActiveAgency() {
      if (not(this.isActiveAccount) && not(this.$route.name === "profile")) {
        this.$eventhub.$emit("modal:open:inactiveAgencyAlert");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/breakpoints.scss";

.md-app-toolbar {
  z-index: 9;
}

.main {
  height: 100vh;
  background-color: $Ebony;
}
</style>

<style lang="scss">
#drift-frame-controller {
  right: 0 !important;
  bottom: 0 !important;
}
</style>
