<template>
  <div class="d-flex ai-center p-relative">
    <Modal size="larger" name="filter-payroll" title="Filtrar" ref="modal">
      <template>
        <div class="d-flex m-15 mt-30 mb-30">
          <CustomInput class="w-50" placeholder="Nome" v-model="eventName" />
          <custom-date-range
            label="Período"
            class="ml-5 w-5k0"
            @input="
              setFiltersPayroll({
                startDate: $event.start,
                endDate: $event.end,
              })
            "
            :value="filterPayroll.start" />
        </div>
        <div class="d-flex m-15 mt-30 mb-30">
          <MultiSelect
            class="w-50"
            placeholder="Serviços"
            :options="getServices"
            v-model="services"></MultiSelect>
          <CustomInput
            class="w-50"
            placeholder="Nome do Profissional"
            v-model="name" />
        </div>
        <div class="d-flex m-15 mt-30 mb-30">
          <SearchHirer
            class="w-50"
            @selected="onHirerSelected"
            :value="filterPayroll.hirer.name" />
        </div>
        <div class="d-flex m-15 mt-30 mb-30">
          <div class="d-flex ai-center">
            <h4 class="mr-5">Colunas</h4>
            <p class="text-Manatee font-italic">
              (Selecione as colunas que deseja visualizar)
            </p>
          </div>
        </div>
        <div class="d-flex m-15 mt-30 mb-30 filter-columns">
          <custom-checkbox
            v-for="(column, index) in columns"
            :key="index"
            :item="column"
            @change="onChange"
            :checked="column.checked"
            value="false"
            class="w-50 mr-0">
            {{ column.label }}
          </custom-checkbox>
        </div>
      </template>

      <template #footer>
        <div class="d-flex f-1 jc-end ai-end wrapped footer p-15">
          <button class="btn secondary mr-5" @click="clearFilter">
            Limpar filtro
          </button>
          <button class="btn outline" @click="close">Cancelar</button>
          <SpinnerButton
            :loading="loaderButton"
            class="btn primary ml-15"
            @click="onFilter">
            Aplicar filtro
          </SpinnerButton>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import SearchHirer from "./SearchHirer.vue";
import { mapActions, mapGetters } from "vuex";
import { getAvailableServices } from "@/services/events/event.http";

export default {
  components: {
    SearchHirer,
  },
  props: ["agencyId"],
  data: () => ({
    availableServices: [],
    options: [
      { label: "Aguardando", value: "AwaitPayment" },
      { label: "Atrasado", value: "Expired" },
      { label: "Cancelado", value: "CanceledByUser" },
      {
        label: "Cancelado por problema na fatura",
        value: "CanceledByProblemOnInvoiceCreation",
      },
      { label: "Marcado como pago", value: "MarkedAsPaid" },
      { label: "Pago", value: "Paid" },
    ],
    loaderButton: false,
  }),

  created() {
    getAvailableServices().then(
      (services) => (this.availableServices = services)
    );
    this.setFiltersPayroll({ paymentStatus: 1 });
  },
  computed: {
    ...mapGetters({
      filterPayroll: "Payment/filterPayroll",
      columns: "Payment/payrollColumns",
    }),

    getServices() {
      return this.availableServices.map((x) => ({
        label: x,
        value: x,
      }));
    },

    eventName: {
      get() {
        return this.filterPayroll.eventName;
      },
      set(value) {
        this.setFiltersPayroll({ eventName: value });
      },
    },

    name: {
      get() {
        return this.filterPayroll.term;
      },
      set(value) {
        this.setFiltersPayroll({ term: value });
      },
    },

    services: {
      get() {
        return this.filterPayroll.services;
      },
      set(value) {
        this.setFiltersPayroll({ services: value });
      },
    },
  },
  methods: {
    ...mapActions({
      getVacanciesDone: "Payment/getVacanciesDone",
      setFiltersPayroll: "Payment/setFiltersPayroll",
      setPayrollColumns: "Payment/setPayrollColumns",
      clearFilterPayroll: "Payment/clearFilterPayroll",
    }),
    close() {
      this.$refs.modal.close();
    },

    onChange(node, checked) {
      this.setPayrollColumns({ node, checked });
    },
    onHirerSelected({ cnpj, name }) {
      this.setFiltersPayroll({ hirer: { cnpj, name } });
    },
    onEventSelected({ cnpj, name }) {
      this.setFiltersPayroll({ cnpj, name });
    },
    onFilter() {
      this.loaderButton = true;
      this.getVacanciesDone()
        .catch((error) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${error}`,
          });
        })
        .finally(() => {
          this.loaderButton = false;
          this.$refs.modal.close();
        });
    },
    clearFilter() {
      this.clearFilterPayroll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.filter {
  border: 2px solid $Malibu;
  border-radius: 10px;
  padding: 25%;
  height: 100%;
  cursor: pointer;
}

.filtered {
  position: absolute;
  top: -6px;
  left: 21px;
  color: $BrightSun;
}

.filter-columns {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  & > label {
    white-space: nowrap;
  }
}
</style>
