<template>
  <div class="register-hirer-form h-100">
    <Tabs :tabs="tabs">
      <Tab tab-id="form" class="p-10">
        <CustomInput placeholder="Nome Fantasia" v-model.trim="hirer.name" />
        <div class="w-50">
          <CustomInput
            placeholder="CNPJ"
            :mask="'##.###.###/####-##'"
            v-model.trim="hirer.cnpj" />
        </div>
        <div class="d-flex">
          <CustomInput
            class="w-100"
            placeholder="E-mail"
            v-model.trim="hirer.email" />
          <CustomInput
            class="w-100"
            placeholder="Telefone"
            :mask="'(##) #####-####'"
            v-model.trim="hirer.phone" />
        </div>
      </Tab>
      <Tab tab-id="services" class="p-10">
        <ServiceList
          @onSelected="hirer.services = $event"
          :selectedServices="hirer.services" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import { not } from "ramda";
import ServiceList from "@/components/shared/ServiceList";

export default {
  components: { ServiceList },
  data: () => ({
    hirer: {
      name: "",
      cnpj: "",
      phone: "",
      email: "",
      services: [],
    },
  }),
  watch: {
    hirer: {
      deep: true,
      handler(val) {
        this.$emit("input", { ...val, email: val.email.toLowerCase() });
      },
    },
  },
  computed: {
    formPending() {
      const { name, cnpj, phone, email } = this.hirer;

      return not(name) || not(cnpj) || not(phone) || not(email);
    },
    hasPending() {
      return this.formPending || not(this.hirer.services.length);
    },
    tabs() {
      return [
        { id: "form", label: "Geral", badge: this.formPending },
        {
          id: "services",
          label: "Serviços",
          badge: not(this.hirer.services.length),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.register-hirer-form {
  text-align: start;

  .custom-card {
    border-radius: 0;
  }

  .tabs-component .tab-header {
    background: $Martinique;

    li {
      border-radius: 0;
      &.active {
        color: $FuchsiaBlue;
        background-image: linear-gradient($FuchsiaBlue, $FuchsiaBlue);
      }
    }
  }
}
</style>
