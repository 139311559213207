<template>
  <Modal
    :name="name"
    class="edit-event-service-modal"
    size="larger"
    @open="onModalOpen"
    ref="modal">
    <template #header>
      <h2 class="title">
        {{ title }}
        <i v-if="isExclusive" class="ml-15 mdi mdi-star"></i>
      </h2>
    </template>

    <template>
      <transition name="fade">
        <Tabs :tabs="tabs" header-class="jc-space-evenly w-100">
          <Tab tab-id="vagas">
            <WorkshiftList
              :dates="distinctDates"
              :service="newService"
              @change="newService.workshifts = $event" />
          </Tab>

          <Tab tab-id="jornada">
            <Responsability
              :service="newService"
              @input="newService.responsabilities = $event" />
          </Tab>

          <Tab tab-id="agencia">
            <AgencyContainer
              class="p-15"
              :isExclusive="isExclusive"
              :agencies="newService.agency"
              v-model="newService.agency"
              :class="{
                'disabled-container': isEdit && currentServiceIsPrev,
              }" />
          </Tab>

          <Tab tab-id="filter">
            <Filters
              v-model="newService.filterFreela"
              :workshifts="newService.workshifts"
              :title="title"
              @update-filters="updateFilters"
              class="pv-10 ph-20"
              :vacancyQuantity="vacancyQuantity"
              :class="{
                'disabled-container': isEdit && currentServiceIsPrev,
              }" />
          </Tab>

          <Tab tab-id="valor">
            <Payment v-model="newService" class="p-10" />
          </Tab>
        </Tabs>
      </transition>
    </template>

    <template #footer>
      <section class="pl-15 pr-15 pt-0">
        <div class="d-flex jc-space-between m-15">
          <div class="d-flex ai-center" v-if="isAgency">
            <toggle-button
              class="mt-6"
              id="hasWorkMaterial"
              :height="32"
              :width="70"
              :sync="true"
              :color="colorSwitch"
              v-model="newService.areVacanciesPublic" />
            <div class="ml-15 mt-2">Tornar essa vaga pública</div>
          </div>
          <div class="d-flex ai-center" v-else></div>
          <div class="d-flex jc-space-between">
            <button class="btn outline" @click="close">Cancelar</button>
            <button
              class="btn primary"
              @click="save"
              :disabled="!(workshifts && hasPayment && hasAgency)">
              Adicionar Demanda
            </button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>
<script>
import { mapGetters } from "vuex";
import { datetimeFormatter } from "@/helpers/dateFormat";

import WorkshiftList from "./WorkshiftList.vue";
import Responsability from "./Responsability.vue";
import AgencyContainer from "./AgencyContainer.vue";
import Payment from "./Payment.vue";
import Filters from "./Filters.vue";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "EditEventServiceModal",
  components: {
    ToggleButton,
    WorkshiftList,
    Responsability,
    AgencyContainer,
    Payment,
    Filters,
  },
  data: () => ({
    name: "EditEventServiceModal",
    title: "",
    colorSwitch: { checked: "#46C5F3", unchecked: "#403A60" },
    filters: {
      invitesToFreelas: [],
      filterFreela: {
        hasProfessionalAttire: undefined,
        hasFixedWork: undefined,
        hasOwnTransport: undefined,
        hasWorkMaterial: undefined,
        hasWorkPictures: undefined,
        maximumToPay: null,
        minAge: null,
        maxAge: null,
        latitude: 0,
        longitude: 0,
        eventDate: null,
        genders: [],
        certificates: [],
        workGroups: [],
      },
    },
    newService: {
      workshifts: [],
      responsabilities: [],
      payment: "",
      agencyProfit: "",
      areVacanciesPublic: false,
      filterFreela: {},
      filters: {},
      invitesToFreelas: [],
      agency: [],
    },
  }),

  computed: {
    ...mapGetters({
      upToFiveFreelas: "NewEvent/upToFiveFreelas",
      isEdit: "EditEvent/isEdit",
      previouslySelected: "EditEvent/previouslySelected",
      hirer: "NewEvent/hirer",
      isAgency: "User/isAgency",
    }),

    currentServiceIsPrev() {
      return this.previouslySelected.includes(this.newService.job);
    },

    sortedWorkshifts() {
      let workshifts = this.newService.workshifts;

      return workshifts.sort(
        (a, b) => new Date(a.checkinDate) - new Date(b.checkinDate)
      );
    },

    distinctDates() {
      function isDateInArray(needle, haystack) {
        for (var i = 0; i < haystack.length; i++) {
          if (needle.getTime() === haystack[i].getTime()) {
            return true;
          }
        }
        return false;
      }

      const uniqueDates = [];

      for (var i = 0; i < this.dates.length; i++) {
        if (!isDateInArray(this.dates[i], uniqueDates)) {
          uniqueDates.push(this.dates[i]);
        }
      }

      return uniqueDates;
    },

    workshifts() {
      return (
        this.newService.workshifts.filter((w) => {
          return (
            !!w.checkinDate &&
            !!w.checkinTime &&
            !!w.checkoutDate &&
            !!w.checkoutTime
          );
        }).length === this.newService.workshifts.length
      );
    },
    hasAgency() {
      return !!this.newService.agency.length || this.upToFiveFreelas;
    },
    hasPayment() {
      return (
        parseFloat(
          this.newService.payment?.replace("R$ ", "")?.replace(",", ".")
        ) > 0 || (this.isAgency ? false : !this.upToFiveFreelas)
      );
    },

    isExclusive() {
      return this.hirer.services
        .map((s) => s.toLowerCase())
        .includes(this.title.toLowerCase());
    },

    dates() {
      return this.$store.getters["EditEvent/isEdit"]
        ? this.$store.getters["EditEvent/dates"]
        : this.$store.getters["NewEvent/dates"];
    },
    tabs() {
      const tabs = [
        { id: "vagas", label: "Vagas", badge: !this.workshifts },
        { id: "jornada", label: "Jornada" },
      ];

      const agencyTab = {
        id: "agencia",
        label: "Empresa",
        badge: !this.hasAgency,
      };
      const filterTab = { id: "filter", label: "Filtro" };
      const valueTab = { id: "valor", label: "Valor", badge: !this.hasPayment };

      if (this.isAgency) {
        return tabs.concat(valueTab, filterTab, agencyTab);
      }

      return this.upToFiveFreelas
        ? tabs.concat(valueTab)
        : tabs.concat(agencyTab);
    },
    vacancyQuantity() {
      return this.newService.workshifts.reduce(
        (acc, { quantity }) => acc + new Number(quantity),
        0
      );
    },

    newServiceCleaned() {
      const newService = this.newService;
      newService.filters = undefined;

      const isCheckinDateAndTimeEmpty = (w) =>
        w.checkinDate != "" && w.checkinTime != "";

      const isCheckoutDateAndTimeEmpty = (w) =>
        w.checkoutDate != "" && w.checkinTime != "";

      newService.workshifts.forEach((w) => {
        if (isCheckinDateAndTimeEmpty(w) || this.isEdit) {
          w.checkin = this.formatDateAndTime(w.checkinDate, w.checkinTime);
        }

        if (isCheckoutDateAndTimeEmpty(w) || this.isEdit) {
          w.checkout = this.formatDateAndTime(w.checkoutDate, w.checkoutTime);
        }
      });

      if (newService.filterFreela === undefined) {
        return newService;
      } else {
        newService.filterFreela = {
          service: this.title,
          certificates: newService.filterFreela.certificates.length
            ? newService.filterFreela.certificates
            : [],
          workGroups: newService.filterFreela.workGroups.length
            ? newService.filterFreela.workGroups
            : [],
          genders: newService.filterFreela.genders.length
            ? newService.filterFreela.genders
            : [],
          hasOwnTransport: newService.filterFreela.hasOwnTransport
            ? newService.filterFreela.hasOwnTransport
            : undefined,
          hasWorkMaterial: newService.filterFreela.hasWorkMaterial
            ? newService.filterFreela.hasWorkMaterial
            : undefined,
          hasWorkPictures: newService.filterFreela.hasWorkPictures
            ? newService.filterFreela.hasWorkPictures
            : undefined,
          hasFixedWork: newService.filterFreela.hasFixedWork
            ? newService.filterFreela.hasFixedWork
            : undefined,
          hasProfessionalAttire: newService.filterFreela.hasProfessionalAttire
            ? newService.filterFreela.hasProfessionalAttire
            : undefined,
          maximumToPay: newService.filterFreela.maximumToPay
            ? newService.filterFreela.maximumToPay
            : undefined,
          minAge: newService.filterFreela.minAge
            ? newService.filterFreela.minAge
            : undefined,
          maxAge: newService.filterFreela.maxAge
            ? newService.filterFreela.maxAge
            : undefined,
          latitude: newService.filterFreela.latitude
            ? newService.filterFreela.latitude
            : undefined,
          longitude: newService.filterFreela.longitude
            ? newService.filterFreela.longitude
            : undefined,
          eventDate: newService.filterFreela.longitude
            ? newService.filterFreela.longitude
            : undefined,
        };
      }

      return newService;
    },
  },
  watch: {
    filters: {
      handler: function (val) {
        this.newService.invitesToFreelas = val.invitesToFreelas;
        this.newService.filterFreela = val.filterFreela;
      },
      deep: true,
    },
  },

  created() {
    this.$eventhub.$on("replicateHours", (values) =>
      this.replicateHours(values)
    );
  },

  methods: {
    formatDateAndTime(date, time) {
      let newDate = datetimeFormatter(date, "YYYY-MM-DD");
      let newTime = datetimeFormatter(time, "THH:mm:ss.sssZ");

      return new Date(newDate + newTime);
    },

    replicateHours(values) {
      const { checkinTime, checkoutTime } = values;

      this.newService.workshifts = this.sortedWorkshifts.map((workshift, i) => {
        let obj;
        i === 0
          ? (obj = workshift)
          : (obj = Object.assign(workshift, { checkinTime, checkoutTime }));

        return obj;
      });
    },
    updateFilters(val) {
      this.filters.invitesToFreelas = val.invitesToFreelas;
      this.filters.filterFreela = val.filterFreela;
    },
    close() {
      this.$refs.modal.close();
      this.clean();
    },
    save() {
      this.isEdit
        ? this.currentServiceIsPrev
          ? this.editService()
          : this.updateService()
        : this.saveNewService();
      this.close();
    },
    saveNewService() {
      this.$store.dispatch("NewEvent/updateService", {
        ...this.newServiceCleaned,
        job: this.title,
      });
    },

    updateService() {
      this.$store.dispatch("EditEvent/updateService", {
        ...this.newServiceCleaned,
        job: this.title,
      });
    },

    editService() {
      this.$store.dispatch("EditEvent/editService", {
        ...this.newServiceCleaned,
        job: this.title,
      });
    },
    clean() {
      this.currentService = {};
      this.payment = "";
      this.title = "";
      this.newService = {
        workshifts: [],
        responsabilities: [],
        payment: "",
        agencyProfit: "",
        areVacanciesPublic: false,
        filterFreela: {},
        invitesToFreelas: [],
        agency: [],
      };
      this.filters = {
        invitesToFreelas: [],
        filterFreela: {
          hasProfessionalAttire: undefined,
          hasFixedWork: undefined,
          hasOwnTransport: undefined,
          hasWorkMaterial: undefined,
          hasWorkPictures: undefined,
          maximumToPay: null,
          minAge: null,
          maxAge: null,
          latitude: 0,
          longitude: 0,
          eventDate: null,
          genders: [],
          certificates: [],
          workGroups: [],
        },
      };
    },
    onModalOpen($event) {
      this.clean();
      this.title = $event.title;
      this.newService = {
        ...$event.currentService,
        payment: $event.currentService.payment.toString(),
        agencyProfit: $event.currentService.agencyProfit.toString(),
      };

      this.filters = Object.assign({
        filterFreela: $event.currentService.filterFreela,
        invitesToFreelas: $event.currentService.invitesToFreelas,
      });
    },
  },

  destroyed() {
    this.$eventhub.$off("replicateHours");
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.edit-event-service-modal {
  .header {
    background: $Martinique;
    border-radius: 5px 5px 0 0;
  }

  h2 {
    font-size: 20px;
  }

  .tabs-component .tab-header {
    background: $Martinique;

    li {
      border-radius: 0;
      &.active {
        color: $FuchsiaBlue;
        background-image: linear-gradient($FuchsiaBlue, $FuchsiaBlue);
      }
    }
  }

  .edit-event-service {
    height: auto;
    overflow: auto;
  }
}
</style>
