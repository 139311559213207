<template>
  <Modal
    class="service-list-modal"
    :size="''"
    :name="name"
    title="Complete seu cadastro!"
    ref="modal">
    <template>
      <div class="p-15">
        Antes de criar sua demanda você
        <br />
        precisa completar seu cadastro.
        <br />
        <br />
        É bem rápido!
        <br />
      </div>
    </template>

    <template #footer>
      <div class="d-flex f-1 jc-center ai-end wrapped footer p-15">
        <button class="btn primary" @click="goToProfilePage">
          Entendi, Vamos lá!
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  data: () => ({
    name: "inactiveAgencyAlert",
  }),

  methods: {
    close() {
      this.$refs.modal.close();
    },

    goToProfilePage() {
      this.close();
      if (this.$route.name !== "profile") {
        this.$router.push({ name: "profile" });
      }
    },
  },
};
</script>
