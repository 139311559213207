<template>
  <Modal
    name="replication"
    title="Duplicar Demanda"
    ref="modal"
    class="replication-modal"
    size="large">
    <template>
      <CustomInput
        placeholder="Título"
        id="title"
        @input="updateTitle"
        :value="eventTitle"
        tip="O título que os profissionais vão receber sobre a demanda." />
      <div class="w-100">
        <CustomInput
          :isInvalid="$v.OS.$error"
          placeholder="Código da OS"
          v-model.trim="$v.OS.$model"
          id="OS" />
        <div class="error d-flex wrapped f-100">
          <span class="d-flex f-100" v-if="$v.OS.$dirty && !$v.OS.maxLength">
            A OS deve conter no máximo 6 dígitos
          </span>
        </div>
      </div>

      <DatesSectionFields />
    </template>

    <template #footer>
      <div class="d-flex f-1 jc-end ai-end wrapped footer p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <button class="btn primary" @click.prevent="replicateEvent">
          Duplicar
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import CustomInput from "../../shared/form/CustomInput.vue";
import DatesSectionFields from "../StepperApp/Step1/DatesSectionFields.vue";

import { mapGetters } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "ReplicationModal",
  components: { CustomInput, DatesSectionFields },
  data: () => ({
    event: {},
  }),
  validations: {
    OS: {
      maxLength: maxLength(6),
    },
    eventTitle: {
      required,
      minLength: minLength(5),
    },
  },
  computed: {
    ...mapGetters({
      editEventForm: "EditEvent/form",
      newEventForm: "NewEvent/form",
    }),
    OS: {
      get() {
        return this.editEventForm.event.OS;
      },
      set(os) {
        this.$store.dispatch("NewEvent/setFormData", {
          event: { ...this.editEventForm.event, OS: os },
        });
      },
    },
    eventTitle: {
      get() {
        return this.editEventForm.event.title + " (cópia)";
      },
      set(title) {
        this.$store.dispatch("NewEvent/setFormData", {
          event: { ...this.editEventForm.event, title },
        });
      },
    },
  },
  mounted() {
    this.$store.dispatch("NewEvent/setEventDays", []);
  },
  methods: {
    updateTitle(title) {
      this.eventTitle = title;
    },
    replicateEvent() {
      this.$store.commit("NewEvent/SET_IS_DUPLICATED", true);

      this.$store.dispatch("NewEvent/duplicate");

      this.$router.push({
        name: "events.new",
        params: { step: 1 },
      });

      this.$eventhub.$emit("modal:close:replication");
    },
    close() {
      this.$store.commit("NewEvent/SET_IS_DUPLICATED", false);
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss">
.replication-modal #modalDescription {
  padding: 1.4rem;
}
</style>
