var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10"},[_c('section',{staticClass:"d-flex f-100 jc-space-between ai-end pl-5 pr-5"},[_c('v-date-picker',{staticClass:"d-flex f-30",class:{ 'disabled-container': _vm.isEdit && _vm.currentServiceIsPrev },attrs:{"mode":"date","is24hr":"","is-dark":"","is-inline":"","popover":{
        placement: _vm.$screens({ sm: 'auto' }),
        visibility: 'click',
      },"update-on-input":false,"available-dates":{ start: _vm.value.date, end: _vm.value.date }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"placeholder":"Entrada","value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkinDate),callback:function ($$v) {_vm.checkinDate=$$v},expression:"checkinDate"}}),_c('v-date-picker',{staticClass:"d-flex f-20",attrs:{"mode":"time","is24hr":"","minute-increment":10,"is-dark":"","is-inline":"","popover":{
        placement: 'bottom',
        visibility: 'click',
      },"update-on-input":false},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkinTime),callback:function ($$v) {_vm.checkinTime=$$v},expression:"checkinTime"}}),_c('v-date-picker',{staticClass:"d-flex f-30",class:{ 'disabled-container': _vm.isEdit && _vm.currentServiceIsPrev },attrs:{"mode":"date","is-dark":"","is-inline":"","popover":{
        placement: _vm.$screens({ sm: 'auto' }),
        visibility: 'click',
      },"update-on-input":false,"available-dates":{ start: _vm.value.date, end: null }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"placeholder":"Saída","value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkoutDate),callback:function ($$v) {_vm.checkoutDate=$$v},expression:"checkoutDate"}}),_c('v-date-picker',{staticClass:"d-flex f-20",attrs:{"mode":"time","is24hr":"","minute-increment":10,"is-dark":"","is-inline":"","popover":{
        placement: 'bottom',
        visibility: 'click',
      },"update-on-input":false},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('CustomInput',_vm._g({attrs:{"value":inputValue,"readonly":true}},inputEvents))]}}]),model:{value:(_vm.checkoutTime),callback:function ($$v) {_vm.checkoutTime=$$v},expression:"checkoutTime"}}),_c('CustomInput',{staticClass:"d-flex f-10",attrs:{"placeholder":"Quantidade","value":_vm.value.quantity},on:{"input":function($event){return _vm.$emit('input', { ..._vm.value, quantity: $event })}}}),_c('i',{staticClass:"mdi mdi-minus-circle-outline cursor-pointer mt-15",on:{"click":function($event){$event.preventDefault();return _vm.$emit('remove')}}})],1),(_vm.workshiftCardIndex === 0 && _vm.workshiftIndex === 0)?_c('md-checkbox',{staticClass:"faq-check-item m-8",model:{value:(_vm.canReplicate),callback:function ($$v) {_vm.canReplicate=$$v},expression:"canReplicate"}},[_vm._v(" Aplicar a mesma hora de entrada e saída para todos os dias ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }