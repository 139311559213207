<template>
  <section>
    <article>
      <CustomAutocomplete
        placeholder="Escolha os membros do grupo"
        label="Digite o nome do membro"
        displayField="name"
        :search="search"
        v-model="input"
        @selected="onMemberSelected">
        <template #option="{ item: { imageUrl, name, favorite, avgRating } }">
          <div class="d-flex ai-center p-10">
            <Avatar :src="imageUrl" size="small" class="mr-10" />
            <div class="mr-10">
              <span>
                {{ name }}
                <i v-if="favorite" class="mdi mdi-star-circle text-orange"></i>
              </span>
              <br />
              <span>
                {{ avgRating }}
                <i class="ml-2 mdi mdi-star"></i>
              </span>
            </div>
          </div>
        </template>
      </CustomAutocomplete>
    </article>
    <Divider class="mv-10" theme="martinique" />
    <article>
      <div>
        <div
          class="selected-member d-flex ai-center jc-space-between p-10"
          v-for="(
            { memberId: id, name, imageUrl, favorite }, key
          ) in editedMembers"
          :key="key">
          <div>
            <Avatar :src="imageUrl" size="small" class="mr-10" />
            <span class="mr-10">{{ name }}</span>
            <i v-if="favorite" class="mdi mdi-star-circle text-orange"></i>
          </div>
          <button class="btn outline icon" @click="onRemoveButtonClick(id)">
            <i class="mdi mdi-close-circle-outline font-size-1-5" />
          </button>
        </div>
      </div>
    </article>
  </section>
</template>
<script>
import { searchMembers } from "@/services/agency/agency.http";

export default {
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    input: "",
    editedMembers: [],
  }),

  watch: {
    members: {
      deep: true,
      handler(value) {
        this.editedMembers = value;
      },
    },
  },
  methods: {
    onMemberSelected(member) {
      if (this.editedMembers.some((m) => m.memberId === member.memberId))
        return;

      this.$emit("selected", member);
      this.input = "";
    },
    search(term) {
      return searchMembers(term).then(({ content }) => content);
    },
    onRemoveButtonClick(id) {
      this.$emit("removed", id);
      this.editedMembers = this.editedMembers.filter((m) => m.memberId !== id);
    },
  },
};
</script>
