<template>
  <Modal
    class="user-groups-modal"
    :name="name"
    size="larger"
    title="Administrar Grupos"
    ref="modal"
    @open="onModalOpen">
    <div class="d-flex">
      <aside class="freela-info d-flex jc-center direction-column ai-center">
        <h3>Freela</h3>
        <Avatar :src="member.imageUrl" :alt="member.name" size="medium" />
        <span>
          {{ member.avgRating }}
          <i class="mdi mdi-star"></i>
        </span>
        <span>
          {{ member.name }}
          <i class="text-orange mdi mdi-star-circle"></i>
        </span>
      </aside>
      <div>
        <span>Escolha os grupos :</span>
      </div>
    </div>

    <template #footer>
      <div class="d-flex jc-end ai-end wrapped p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <SpinnerButton
          :loading="loading"
          class="btn primary ml-15"
          @click="save">
          Salvar
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  data: () => ({
    name: "userGroups",
    loading: false,
    currentMember: {},
  }),
  computed: {
    member() {
      return this.currentMember;
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    save() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 500);
    },
    onModalOpen($event) {
      const { member } = $event;
      this.currentMember = member;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.user-groups-modal {
  .freela-info {
    min-width: 300px;
  }
}
</style>
