<template>
  <section class="resume-container">
    <h4 class="mt-0 mb-10">Resumo</h4>
    <Divider theme="steel-gray" />

    <div class="d-flex jc-space-between">
      <div class="d-flex direction-column">
        <h5 class="mt-10 mb-10">Quantidade de profissionais</h5>
        <h5 class="mt-0 mb-10">Valor total do serviço</h5>
        <template v-if="isAgency">
          <h5 class="mt-0 mb-10">Lucro total da agência</h5>
        </template>
      </div>

      <div class="d-flex direction-column">
        <p class="mt-10 mb-10 text-end font-size-08">{{ vacancyQuantity }}</p>
        <p class="mt-0 mb-10 text-end font-size-08">{{ service.payment }}</p>
        <template v-if="isAgency">
          <p class="mt-0 mb-10 text-end font-size-08">
            {{ service.agencyProfit }}
          </p>
        </template>
      </div>
    </div>
    <div class="d-flex jc-space-between ai-center">
      <h5 class="mt-10 mb-0 font-size-08">Valor final</h5>
      <p class="text-malibu mt-10 mb-0 font-size-08">
        R$ {{ this.totalValue | toCurrency }}
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: { service: Object },
  computed: {
    ...mapGetters({
      isAgency: "User/isAgency",
    }),
    vacancyQuantity() {
      return this.service.workshifts.reduce(
        (acc, { quantity }) => acc + new Number(quantity),
        0
      );
    },
    totalValue() {
      const { payment, agencyProfit } = this.service;

      return (
        (this.replaceValue(payment) + this.replaceValue(agencyProfit)) *
        this.vacancyQuantity
      );
    },
  },
  methods: {
    replaceValue(value) {
      return parseFloat(value?.replace("R$ ", "")?.replace(",", "."));
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";

.resume-container {
  background-color: $Minsk;
  width: 100%;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
}
</style>
