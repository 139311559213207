<template>
  <GmapMap
    ref="mapRef"
    :center="{ lat: 10, lng: 10 }"
    :zoom="10"
    :streetViewControl="false"
    :options="{ styles: mapStyle }">
    <GmapInfoWindow
      :options="infoWindowOptions"
      :position="infoWindowPosition"
      :opened="showInfoWindow"></GmapInfoWindow>
    <gmap-custom-marker
      @click.native="toggleInfoWindow(m, index)"
      v-for="(m, index) in markers"
      :key="index"
      :marker="m.latLng">
      <div class="d-flex direction-column ai-center">
        <span v-if="!m.isDestination" class="font-size-1 text-malibu text-bold">
          {{ m.info }}
        </span>
        <Avatar
          v-if="!m.isDestination"
          :src="teamMembers.find((t) => t.freelaId == m.freelaId).image" />
        <img
          v-if="m.isDestination"
          :src="require('@/assets/map-pin-fill.svg')"
          alt=""
          width="50"
          height="50" />
        <span v-if="m.isDestination" class="font-size-1 text-malibu text-bold">
          Local da demanda
        </span>
      </div>
    </gmap-custom-marker>
  </GmapMap>
</template>
<script>
import { formatDate, dateToString } from "@/helpers/dateFormat";
import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  props: {
    destination: {
      type: Object,
      default: () => ({}),
    },
    teamMembers: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    markers: [],
    showInfoWindow: false,
    infoWindowPosition: { lat: 0, lng: 0 },
    currentWindowOpen: null,
    infoWindowOptions: {
      content: "xablau",
      pixelOffset: {
        width: "auto",
        height: -35,
      },
    },
    mapStyle: [
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#23203f",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#23203f",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#403a60",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#101010",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#7779c7",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#6f71b1",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#8080c0",
          },
        ],
      },
    ],
  }),

  watch: {
    destination: {
      deep: true,
      handler(value) {
        this.markers = [...this.markers, value];
      },
    },
    locations: {
      deep: true,
      handler(value) {
        this.markers = [...value, ...this.markers];
      },
    },
    markers() {
      this.loadMapMarkers();
    },
  },
  methods: {
    loadMapMarkers() {
      const addMarkerToMap = (map) =>
        this.markers.forEach((item) => map.panTo(item.latLng));
      this.$refs.mapRef.$mapPromise.then((map) => addMarkerToMap(map));
    },
    toggleInfoWindow(marker, index) {
      this.infoWindowPosition = marker.latLng;
      this.infoWindowOptions = {
        ...this.infoWindowOptions,
        content: this.getWindowInfo(marker),
      };

      if (this.currentWindowOpen === index)
        this.showInfoWindow = !this.showInfoWindow;
      else {
        this.showInfoWindow = true;
        this.currentWindowOpen = index;
      }
    },

    getWindowInfo(marker) {
      const occuredAt = () => {
        const date = new Date(marker.occuredAt);
        return `${dateToString(date)} ${formatDate(date, "HH")}:${formatDate(
          date,
          "ii"
        )} `;
      };

      if (marker.isDestination) {
        return `
                    <article style="color:#000; display:flex;align-items:center;justify-content:space-around;flex:0 1 100%; flex-wrap:wrap">
                    <p style="flex:0 1 100%">
                        <span>Local da Demanda:</span>
                        <span style="margin-left:5px;"><strong>${
                          marker.name || "Não informado"
                        }</strong></span>
                    </p>
                    <p style="flex:0 1 100%">
                        <span style="margin-left:5px;"><strong>${
                          marker.address
                        }</strong></span>
                    </p>
                    </article>`;
      }
      const image = this.teamMembers.find(
        (t) => t.freelaId == marker.freelaId
      ).image;
      const freelaInfoString = `<article 
                style="color:#000; display:flex;align-items:center;justify-content:space-around;flex:0 1 100%; flex-wrap:wrap">
                <div style="flex:0 1 100%">
                    <img style="border-radius:50px;height:35px;width:35px;"src="${image}"  />
                    <span style="margin-left:5px;"><strong>${
                      marker.info
                    }</strong></span>
                </div>
                <div style="flex:0 1 100%">
                    <span style="margin-left:5px;">Ultima atualização: <strong>${occuredAt()}</strong></span>
                </div>
            </article>`;
      return freelaInfoString;
    },
  },
};
</script>
<style lang="scss">
.vue-map {
  display: flex;
  position: relative !important;
  flex: 1;
  height: 100% !important;
}
</style>
