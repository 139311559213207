<template>
  <Modal
    :name="name"
    @open="onModalOpen"
    title="Avaliar profissional"
    ref="modal">
    <section class="d-flex f-100 wrapped jc-center pt-10 media">
      <article class="d-flex f-100 wrapped jc-center">
        <div class="d-flex f-100 jc-center pb-15">
          <img
            v-if="!availableVacancy"
            class="avatar radius-50"
            :src="image || imageUser"
            alt />
          <MultipleAvatar
            :showText="false"
            v-else
            :membersData="multipleImages" />
        </div>
      </article>
      <article class="d-flex f-50 star wrapped">
        <span class="font-size-1 mb-10">{{ nome }}</span>
        <article class="d-flex f-100 wrapped mb-15">
          <span class="d-flex f-100 font-size-06">Pontualidade*</span>
          <div class="d-flex f-80 star-wrapper">
            <star-rating
              class="d-flex f-100 jc-space-evenly"
              v-model="punctuality"
              :show-rating="false"
              :star-size="25"
              :rounded-corners="true"
              :padding="10"
              active-color="#FFCC00"></star-rating>
          </div>
        </article>
        <article class="d-flex f-100 wrapped mb-15">
          <span class="d-flex f-100 font-size-06">Vestimenta*</span>
          <div class="d-flex f-80 star-wrapper">
            <star-rating
              class="d-flex f-100 jc-space-evenly"
              v-model="clothing"
              :show-rating="false"
              :star-size="25"
              :rounded-corners="true"
              :padding="10"
              active-color="#FFCC00"></star-rating>
          </div>
        </article>
        <article class="d-flex f-100 wrapped mb-15">
          <span class="d-flex f-100 font-size-06">Postura*</span>
          <div class="d-flex f-80 star-wrapper">
            <star-rating
              class="d-flex f-100 jc-space-evenly"
              v-model="position"
              :show-rating="false"
              :star-size="25"
              :rounded-corners="true"
              :padding="10"
              active-color="#FFCC00"></star-rating>
          </div>
        </article>
        <article class="d-flex f-100 wrapped mb-15">
          <span class="d-flex f-100 font-size-06">Habilidades*</span>
          <div class="d-flex f-80 star-wrapper">
            <star-rating
              class="d-flex f-100 jc-space-evenly"
              v-model="skills"
              :show-rating="false"
              :star-size="25"
              :rounded-corners="true"
              :padding="10"
              active-color="#FFCC00"></star-rating>
          </div>
        </article>
      </article>
      <article class="d-flex f-40 wrapped">
        <span class="font-size-1 mb-10">Comentário</span>
        <CustomInput
          v-model="title"
          class="d-flex f-100 pb-5"
          placeholder="Titulo" />
        <div class="d-flex f-100 wrapped">
          <span class="d-flex f-100 comment pb-5">Comentário</span>
          <textarea
            v-model="comments"
            class="d-flex f-100 area radius-5"
            rows="5"
            cols="38"></textarea>
        </div>
        <div class="d-flex f-100 mt-20 pt-20 jc-center">
          <button class="btn outline" @click="close">Cancelar</button>
          <button class="btn primary" @click="saveRating">Salvar</button>
        </div>
      </article>
    </section>
    <section slot="footer" class="d-flex f-100"></section>
  </Modal>
</template>

<script>
import MultipleAvatar from "@/components/events/EventInfo/MultipleAvatar/MultipleAvatar.vue";
import {
  freelaSingularRating,
  freelaAllRating,
} from "@/services/rating/rating.http";
export default {
  components: {
    MultipleAvatar,
  },
  data: () => ({
    name: "memberAvaliation",
    punctuality: 0,
    position: 0,
    clothing: 0,
    skills: 0,
    comments: "",
    title: "",
    nome: "",
    image: "",
    freelaId: "",
    allFreelaId: [],
    multipleImages: [],
    availableVacancy: false,
    operationId: "",
  }),
  methods: {
    onModalOpen($event) {
      this.ratingAll = $event.ratingAll;
      this.operationId = $event.operationId;
      if ($event.ratingAll === true) {
        this.allRating($event);
      } else {
        this.singleRating($event);
      }
    },
    singleRating(data) {
      this.clearModalOptions();
      const { image, description, availableVacancy, freelaId } = data.members;
      (this.image = image),
        (this.nome = description),
        (this.freelaId = freelaId),
        (this.availableVacancy = availableVacancy);
    },
    allRating(data) {
      this.clearModalOptions();
      this.availableVacancy = true;
      this.allFreelaId = data.members.map(({ freelaId }) => freelaId);
      this.multipleImages = data.members.map(({ image }) => image);
      this.nome = "Avaliação média  do Serviço";
    },
    clearModalOptions() {
      this.punctuality = 0;
      this.skills = 0;
      this.position = 0;
      this.clothing = 0;
      this.title = "";
      this.comments = "";
    },
    saveRating() {
      if (this.ratingAll === true) {
        this.allRatingSave();
      } else {
        this.singleRatingSave();
      }
    },
    singleRatingSave() {
      const body = {
        operationId: this.operationId,
        title: this.title,
        comment: this.comments,
        punctuality: this.punctuality,
        clothing: this.clothing,
        skills: this.skills,
        position: this.position,
      };
      freelaSingularRating(this.freelaId, body)
        .then(() => {
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Avaliação Finalizada!",
          });
          this.close();
        })
        .catch(({ response }) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${response.data.errorMessage}`,
          });
        });
    },
    allRatingSave() {
      const body = {
        operationIds: this.operationId,
        freelasId: this.allFreelaId,
        title: this.title,
        comment: this.comments,
        stars: this.rating,
        punctuality: this.punctuality,
        clothing: this.clothing,
        skills: this.skills,
        position: this.position,
      };
      freelaAllRating(body)
        .then(() => {
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Avaliação Finalizada!",
          });
          this.close();
        })
        .catch(({ response }) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${response.data.errorMessage}`,
          });
        });
    },
    close() {
      this.$refs.modal.close();
    },
  },
  computed: {
    imageUser() {
      return require("@/assets/icon_avatar.svg");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.star {
  width: 35%;
  height: 40px;
  background: $Mirage;
  padding-bottom: 18px;
  padding-left: 5px;

  .star-wrapper {
    background: $Martinique;
    border-radius: 20px;
    padding-bottom: 1px;
  }
}
.area {
  background: $Ebony;
}
.comment {
  font-size: 12px;
}
textarea {
  border: 2px solid;
  color: white;
}
textarea:focus {
  outline: none !important;
  color: white;
  border: 0.1rem solid;
  border-color: $FuchsiaBlue;
}
.avatar {
  position: relative;
  height: 60px;
  object-fit: cover;
  border: 2px solid grey;
  width: 60px;
  min-width: 60px;
}

@media (max-width: 1330px) {
  .media {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
