<template>
  <Modal
    class="service-list-modal"
    :name="name"
    @open="onModalOpen"
    title="Solicitar pagamento"
    ref="modal"
    size="mini">
    <section class="w-100 d-flex direction-column ai-space-between p-25 pr-100">
      <span class="mb-35 font-size-08">
        Analise atentamente todas as informações antes de prosseguir com o
        pagamento:
      </span>
      <div class="d-flex jc-space-between">
        <span>
          Profissionais selecionados:
          <br />
          {{ freelasToPay.length }}
        </span>
        <span>
          Total a pagar
          <br />
          {{ freelasToPay | formatPaymentValue }}
        </span>
      </div>
      <span v-if="eventQuantity">
        <br />
        Quantidade de eventos selecionados:
        <br />
        {{ eventQuantity }}
      </span>
    </section>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <SpinnerButton
          :loading="loading"
          class="btn primary"
          @click="toPayment">
          Confirmar
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    name: "confirmation-payment",
    title: "Confirmar pagamento",
    totalSelected: 0,
    eventQuantity: null,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      basicInfo: "EventDetails/basicInfo",
      isAgency: "User/isAgency",
      freelasToPay: "Payment/freelasToPay",
    }),
  },
  filters: {
    formatPaymentValue(val) {
      const payment = val
        .map((p) => p.payment)
        .reduce(
          (acc, currentValue) => acc + currentValue,
          val[0]?.singleChargeByPayment ?? 0
        );
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(payment);
    },
  },
  methods: {
    ...mapActions({
      udpateVacanciesDone: "Payment/udpateVacanciesDone",
    }),
    close() {
      this.$refs.modal.close();
    },

    onModalOpen($event) {
      this.$store.dispatch("Payment/freelasToPay", $event.freelasToPay);
      this.eventQuantity = $event.eventQuantity;
    },

    toPayment() {
      this.$store.dispatch("Payment/paymentBatch").then(() => {
        this.$refs.modal.close();
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
</style>
