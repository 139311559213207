<template>
  <section class="content d-flex f-100 wrapped jc-center ai-start">
    <article class="d-flex f-100 wrapped">
      <div class="header d-flex f-1 jc-space-between">
        <span>Dia {{ date.getDate() }}</span>
        <span class="cursor-pointer" @click.prevent="addNewWorkshift">
          Adicionar turno
        </span>
      </div>
      <Divider class="mv-10" color="#18142f" />
      <Workshift
        v-for="(item, index) in workshifts"
        :key="index"
        v-model="workshifts[index]"
        :workshiftCardIndex="workshiftCardIndex"
        :workshiftIndex="index"
        :service="service"
        @remove="removeHandler(index)"></Workshift>
    </article>
  </section>
</template>
<script>
import Workshift from "./Workshift.vue";
export default {
  name: "WorkshiftCard",

  components: {
    Workshift,
  },
  data() {
    return {
      workshifts: [],
    };
  },

  created() {
    const byDate = (w) =>
      new Date(w.date).toLocaleDateString() === this.date.toLocaleDateString();
    const workshiftsFounded = this.service.workshifts.filter(byDate);
    if (!workshiftsFounded.length) this.addNewWorkshift();
    else {
      this.workshifts = [...workshiftsFounded];
    }
  },
  props: {
    workshiftCardIndex: {
      type: Number,
      default: 0,
    },
    date: {
      type: Date,
      default: {},
    },
    service: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    workshifts: {
      deep: true,
      immediate: false,
      handler(value) {
        this.$emit("change", [...value]);
      },
    },
  },

  methods: {
    newWorkshift() {
      return {
        checkin: "",
        checkinDate: this.date,
        checkinTime: "",
        checkout: "",
        checkoutDate: this.date,
        checkoutTime: "",
        quantity: 1,
        date: this.date.toISOString(),
      };
    },
    addNewWorkshift() {
      this.workshifts = [...this.workshifts, this.newWorkshift()];
    },
    removeHandler(index) {
      if (this.workshifts.length > 1) this.workshifts.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/colors.scss";

section {
  background-color: $Martinique;
  border-radius: 10px;
  margin: 20px 0;

  // min-height: 200px;

  .content {
    justify-content: space-around;
    padding: 20px 0;
    margin-left: 1rem;
    margin-right: 1rem;

    div {
      span {
        font-size: 14px;
        align-self: center;
        margin: 0 20px;
      }
    }
  }
}
</style>
