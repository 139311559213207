<template>
  <Modal
    :name="name"
    title="Correção de data e hora"
    @open="onModalOpen"
    ref="modal">
    <template #header>
      <h2><small>Correção de hora e data</small></h2>
    </template>
    <section class="pv-15 ph-20">
      <div class="entrance ai-center">
        <h3 class="ph-15 pt-15">Entrada</h3>
        <Divider />
        <div class="d-flex">
          <small class="ph-15 pt-10">Planejado</small>
          <small class="ph-15 pt-10">{{ checkin.planned | formatData }}</small>
        </div>
        <div class="d-flex">
          <small class="ph-15 pt-10">Realizado</small>
          <small class="ph-15 pt-10">{{ checkin.done | formatData }}</small>
        </div>
        <div class="d-flex ai-center">
          <small class="ph-15 pv-10">Correção:</small>
          <div class="d-flex mv-10">
            <v-date-picker
              v-model="checkinDate"
              mode="date"
              is24hr
              is-dark
              is-inline
              class="d-flex f-30"
              :popover="{
                placement: $screens({ sm: 'auto' }),
                visibility: 'click',
              }"
              :update-on-input="false">
              <template v-slot="{ inputValue, inputEvents }">
                <CustomInput
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="true"></CustomInput>
              </template>
            </v-date-picker>
            <v-date-picker
              v-model="checkinTime"
              mode="time"
              is24hr
              is-dark
              is-inline
              class="d-flex f-30"
              :popover="{
                placement: 'bottom',
                visibility: 'click',
              }"
              :update-on-input="false">
              <template v-slot="{ inputValue, inputEvents }">
                <CustomInput
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="true"></CustomInput>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>
      <div class="entrance ai-center">
        <h3 class="ph-15 pt-15">Saida</h3>
        <Divider />
        <div class="d-flex">
          <small class="ph-15 pt-10">Planejado</small>
          <small class="ph-15 pt-10">{{ checkout.planned | formatData }}</small>
        </div>
        <div class="d-flex">
          <small class="ph-15 pt-10">Realizado</small>
          <small class="ph-15 pt-10">{{ checkout.planned | formatData }}</small>
        </div>
        <div class="d-flex ai-center">
          <small class="ph-15 pv-10">Correção:</small>
          <div class="d-flex mv-10">
            <v-date-picker
              v-model="checkoutDate"
              mode="date"
              is24hr
              is-dark
              is-inline
              class="d-flex f-30"
              :popover="{
                placement: $screens({ sm: 'auto' }),
                visibility: 'click',
              }"
              :update-on-input="false">
              <template v-slot="{ inputValue, inputEvents }">
                <CustomInput
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="true"></CustomInput>
              </template>
            </v-date-picker>
            <v-date-picker
              v-model="checkoutTime"
              mode="time"
              is24hr
              is-dark
              is-inline
              class="d-flex f-30"
              :popover="{
                placement: 'bottom',
                visibility: 'click',
              }"
              :update-on-input="false">
              <template v-slot="{ inputValue, inputEvents }">
                <CustomInput
                  :value="inputValue"
                  v-on="inputEvents"
                  :readonly="true"></CustomInput>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>
    </section>
    <template #footer>
      <div class="d-flex">
        <div class="d-flex fg-1"></div>
        <div class="d-flex m-15">
          <button class="btn outline">
            <span class="ph-30" @click="close">Cancelar</span>
          </button>
          <button @click="editSchedule" class="btn primary">
            <span class="ph-40">Salvar</span>
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { datetimeFormatter } from "@/helpers/dateFormat";

export default {
  name: "correctSchedules",

  filters: {
    formatData(data) {
      if (data !== "") {
        return new Date(data).toLocaleDateString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return "--";
    },
  },

  data: () => ({
    name: "correctSchedules",
    openModal: false,
    checkin: {},
    checkout: {},
    operationId: "",
    job: "",
    vacancyId: "",
    freelaId: "",
    checkinDate: "",
    checkinTime: "",
    checkoutDate: "",
    checkoutTime: "",
  }),
  methods: {
    close() {
      this.clear();
      this.$refs.modal.close();
    },

    clear() {
      this.checkinDate = "";
      this.checkinTime = "";
      this.checkoutDate = "";
      this.checkoutTime = "";
    },

    formatDateAndTime(date, time) {
      let newDate = datetimeFormatter(date, "YYYY-MM-DD");
      let newTime = datetimeFormatter(time, "THH:mm:ss.sssZ");

      return newDate + newTime;
    },

    dataCleaned() {
      const isCheckinDateAndTimeEmpty = () =>
        this.checkinDate != "" && this.checkinTime != "";
      const isCheckoutDateAndTimeEmpty = () =>
        this.checkoutDate != "" && this.checkoutTime != "";

      let checkin;
      let checkout;

      if (isCheckinDateAndTimeEmpty())
        checkin = this.formatDateAndTime(this.checkinDate, this.checkinTime);

      if (isCheckoutDateAndTimeEmpty())
        checkout = this.formatDateAndTime(this.checkoutDate, this.checkoutTime);

      return {
        vacancyId: this.vacancyId,
        freelaId: this.freelaId,
        checkin,
        checkout,
      };
    },
    onModalOpen($event) {
      const { operationId, job, vacancyId, freelaId, checkin, checkout } =
        $event;

      this.operationId = operationId;
      this.job = job;
      this.vacancyId = vacancyId;
      this.freelaId = freelaId;
      this.checkin = checkin;
      this.checkout = checkout;
    },

    async editSchedule() {
      try {
        this.authValidation();
        this.saveEditSchedule();
        this.close();
      } catch ({ message }) {
        this.showFeedback(message, "warning");
      }
    },

    saveEditSchedule() {
      this.$store.dispatch("EventDetails/saveSchedule", {
        operationId: this.operationId,
        job: this.job,
        body: this.dataCleaned(),
      });
    },

    authValidation() {
      if (!localStorage.getItem("LANUP-TOKEN")) {
        const urlToGoBack = this.$route.fullPath;
        localStorage.setItem("urlToGoBack", urlToGoBack);
        this.$router.push({ name: "access.login" });
      }
    },
  },

  watch: {
    checkinDate(val) {
      this.checkinTime = val;
    },

    checkoutDate(val) {
      this.checkoutTime = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.entrance {
  background-color: $Minsk;
  width: 100%;
  height: 190px;
  border-radius: 8px;
}

.fg-1 {
  flex-grow: 1;
}

h2 {
  color: white;
}
</style>
