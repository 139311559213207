import axios from "axios";
import { ifElse } from "ramda";

const performError = (e) => {
  const error = e.errorMessage ? { message: e.errorMessage } : e;
  throw error;
};

const isSuccess = ({ data: { isSuccess } }) => isSuccess;
const returnResult = ({ data: { result } }) => result;

const validateReturn = ifElse(isSuccess, returnResult, performError);

const freelaSingularRating = (id, body) =>
  axios
    .post(`freelas/${id}/ratings`, body)
    .then(validateReturn)
    .catch(performError);

const freelaAllRating = (body) =>
  axios.post(`freelas/ratings`, body).then(validateReturn).catch(performError);

const agenciesRating = (data) =>
  axios
    .post(`agencies/${data.id}/classifications`, data)
    .then(validateReturn)
    .catch(performError);

const hirersRating = (data) =>
  axios
    .post(`hirers/${data.id}/classifications`, data)
    .then(validateReturn)
    .catch(performError);

export { freelaAllRating, freelaSingularRating, agenciesRating, hirersRating };
