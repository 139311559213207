<template>
  <section class="filters w-100">
    <div class="p-10 mvh">
      <p>Encontre os perfis específicos para atender sua demanda!</p>
      <br />

      <div class="d-flex">
        <MultiSelect
          style="flex: 1"
          class="w-100"
          placeholder="Grupo de trabalho"
          v-model="filters.workGroups"
          :options="workGroupsOptions"
          @input="searchWorkgroups"
          autoComplete
          placeholderSearch="Pesquisar grupo"
          :loader="loader"></MultiSelect>
        <MultiSelect
          style="flex: 1"
          class="w-100"
          placeholder="Gênero"
          :options="genderOptions"
          v-model="filters.genders"></MultiSelect>
        <MultiSelect
          style="flex: 1"
          class="w-100"
          placeholder="Certificado"
          v-model="filters.certificates"
          :options="certificatesOptions"></MultiSelect>
      </div>
      <br />

      <div class="d-flex">
        <CustomInput
          class="w-100"
          style="flex: 1; margin: 0px 4px 0px 0px; padding: 0px 0px 0px 2px"
          placeholder="Desejo pagar no máximo (R$)"
          v-model="filters.maximumToPay" />
        <div
          class="d-flex ai-center w-100 p-relative"
          style="flex: 1"
          :class="{ 'siblings-input': siblingsInputOnFocus }">
          <CustomInput
            @focus="siblingsInputFocus"
            @blur="siblingsInputBlur"
            class="w-100 text-align-center"
            placeholder="Idade(anos)"
            v-model="filters.minAge" />
          <label class="from-to-input" for="siblingsInput">
            <div>Até</div>
          </label>
          <CustomInput
            @focus="siblingsInputFocus"
            @blur="siblingsInputBlur"
            class="w-100 text-align-center"
            placeholder=" "
            v-model="filters.maxAge" />
        </div>
        <div class="w-100 p-4" style="flex: 1">
          <div for="hasWorkPictures" class="switch-title">Fotos de jobs</div>
          <toggle-button
            class="mt-8 ml-4"
            id="hasWorkPictures"
            v-model="filters.hasWorkPictures"
            :height="32"
            :width="70"
            :color="colorSwitch"
            :sync="true" />
        </div>
      </div>
      <br />

      <div class="d-flex">
        <div class="w-100 p-4">
          <div for="hasAvailability" class="switch-title">
            Disponibilidade diária
          </div>
          <toggle-button
            class="mt-8 ml-4"
            id="hasAvailability"
            v-model="hasAvailability"
            :height="32"
            :width="70"
            :color="colorSwitch"
            :sync="true" />
        </div>
        <div class="w-100 p-4">
          <div for="hasLocalization" class="switch-title">
            Localização aproximada
          </div>
          <toggle-button
            class="mt-8 ml-4"
            id="hasLocalization"
            v-model="hasLocalization"
            :height="32"
            :width="70"
            :color="colorSwitch"
            :sync="true" />
        </div>
        <div class="w-100 p-4">
          <div for="hasOwnTransport" class="switch-title">
            Transporte próprio
          </div>
          <toggle-button
            class="mt-8 ml-4"
            id="hasOwnTransport"
            v-model="filters.hasOwnTransport"
            :height="32"
            :width="70"
            :color="colorSwitch"
            :sync="true" />
        </div>
      </div>
      <br />
      <div class="d-flex">
        <div class="w-100 p-4">
          <div for="hasWorkMaterial" class="switch-title">
            Material de trabalho
          </div>
          <toggle-button
            class="mt-8 ml-4"
            id="hasWorkMaterial"
            v-model="filters.hasWorkMaterial"
            :height="32"
            :width="70"
            :color="colorSwitch"
            :sync="true" />
        </div>
        <div class="w-100 p-4">
          <div for="hasFixedWork" class="switch-title">Trabalhar fixo</div>
          <toggle-button
            class="mt-8 ml-4"
            id="hasFixedWork"
            v-model="filters.hasFixedWork"
            :height="32"
            :width="70"
            :color="colorSwitch"
            :sync="true" />
        </div>
        <div class="w-100 p-4">
          <div for="hasProfessionalAttire" class="switch-title">Vestimenta</div>
          <toggle-button
            class="mt-8 ml-4"
            id="hasProfessionalAttire"
            v-model="filters.hasProfessionalAttire"
            :height="32"
            :width="70"
            :color="colorSwitch"
            :sync="true" />
        </div>
      </div>
      <p>Resultado do seu filtro:</p>
    </div>

    <div class="bg-result">
      <div class="result-card">
        <div class="status">
          <div class="status-background-icon" v-if="filteredCount == null">
            <i class="mdi mdi-information-outline status-icon"></i>
          </div>

          <div
            class="status-background-icon red"
            v-else-if="filteredCount == 0">
            <i class="mdi mdi-close-circle-outline status-icon"></i>
          </div>

          <div
            class="status-background-icon yellow"
            v-else-if="filteredCount < vacancyQuantity">
            <i class="mdi mdi-alert-outline status-icon"></i>
          </div>

          <div class="status-background-icon blue" v-else>
            <i class="mdi mdi-check-circle-outline status-icon"></i>
          </div>
        </div>

        <div class="message">
          <div class="message-body" v-if="filteredCount == null">
            <div class="title">Nenhum filtro selecionado!</div>
            <span class="description">
              Selecione pelo menos um filtro para que a consulta de talentos
              seja realizada.
            </span>
          </div>

          <div class="message-body" v-else-if="filteredCount == 0">
            <div class="title">Ops!</div>
            <span class="description">
              Seu filtro é muito especifico. Nenhum talento encontrado.
            </span>
          </div>

          <div class="message-body" v-else-if="filteredCount < vacancyQuantity">
            <div class="title">Poucos talentos encontrados!</div>
            <span class="description">
              Somente
              <strong style="color: #ff961f">
                {{ filteredCount }}
                {{ filteredCount > 1 ? "Talentos" : "Talento" }}
              </strong>
              {{ filteredCount > 1 ? "foram encontrados" : "foi encontrado" }}
            </span>
          </div>

          <div class="message-body" v-else>
            <div class="title">INCRÍVEL!</div>
            <span class="description">
              <strong style="color: #46c5f3">
                {{ filteredCount }}
                {{ filteredCount > 1 ? "Talentos" : "Talento" }}
              </strong>
              {{ filteredCount > 1 ? "foram encontrados" : "foi encontrado" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { ToggleButton } from "vue-js-toggle-button";
import axios from "axios";
import qs from "qs";

export default {
  components: {
    ToggleButton,
  },
  data: () => ({
    colorSwitch: { checked: "#46C5F3", unchecked: "#403A60" },
    filteredCount: null,
    freelaIds: [],
    siblingsInputOnFocus: false,
    workGroupsOptions: [],
    certificatesOptions: [
      { label: "Curso Técnico", value: 1 },
      { label: "Curso Livre", value: 2 },
      { label: "Graduação", value: 3 },
      { label: "Pós graduação", value: 4 },
      { label: "MBA", value: 5 },
      { label: "Vacinação Covid", value: 6 },
    ],
    genderOptions: [
      { label: "Masculino", value: "Male" },
      { label: "Feminino", value: "Female" },
      { label: "Homem Trans", value: "TransMan" },
      { label: "Mulher Trans", value: "TransWoman" },
      { label: "Queer+", value: "Queer" },
    ],
    eventDate: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    hasLocalization: false,
    hasAvailability: false,
    enteredTime: 0,
    filters: {
      hasProfessionalAttire: undefined,
      hasFixedWork: undefined,
      hasOwnTransport: undefined,
      hasWorkMaterial: undefined,
      hasWorkPictures: undefined,
      maximumToPay: null,
      minAge: null,
      maxAge: null,
      latitude: 0,
      longitude: 0,
      eventDate: null,
      genders: [],
      certificates: [],
      workGroups: [],
    },
    loader: false,
  }),
  computed: {
    ...mapGetters({
      formNewEvent: "NewEvent/form",
    }),
  },
  props: {
    workshifts: {
      default: () => [],
    },
    title: {
      default: "",
    },
    value: {
      default: () => ({
        hasProfessionalAttire: undefined,
        hasFixedWork: undefined,
        hasOwnTransport: undefined,
        hasWorkMaterial: undefined,
        hasWorkPictures: undefined,
        maximumToPay: null,
        minAge: null,
        maxAge: null,
        latitude: 0,
        longitude: 0,
        eventDate: null,
        genders: [],
        certificates: [],
        workGroups: [],
      }),
    },
    vacancyQuantity: {
      default: 0,
    },
  },
  watch: {
    filters: {
      handler: function (val, oldVal) {
        if (val.latitude != oldVal.latitude) return;
        if (val.longitude != oldVal.longitude) return;

        const initialFilters = {
          hasProfessionalAttire: undefined,
          hasFixedWork: undefined,
          hasOwnTransport: undefined,
          hasWorkMaterial: undefined,
          hasWorkPictures: undefined,
          maximumToPay: null,
          minAge: null,
          maxAge: null,
          latitude: val.latitude,
          longitude: val.longitude,
          eventDate: null,
          genders: [],
          certificates: [],
          workGroups: [],
        };

        if (JSON.stringify(initialFilters) !== JSON.stringify(val)) {
          this.normalizeFilters(val);
        } else {
          this.filters = this.$props.value;
        }
      },
      deep: true,
    },
    hasLocalization() {
      this.normalizeFilters(this.filters);
    },
    hasAvailability() {
      this.normalizeFilters(this.filters);
    },
  },
  mounted() {
    this.getWorkgroupOptions();
    this.getPlaceLatLong();
  },
  methods: {
    searchWorkgroups(val) {
      this.loader = true;
      axios
        .get("/agencies/workgroups", { params: { term: val } })
        .then(({ data }) => data)
        .then(({ result }) => {
          this.workGroupsOptions = result.content.map((x) => ({
            label: x.name,
            value: x.name,
          }));
        })
        .finally(() => (this.loader = false));
    },
    normalizeFilters(val) {
      const eventDate = this.hasAvailability
        ? [
            ...new Set(
              this.workshifts.map(
                (i) => this.eventDate[new Date(i.date).getDay()]
              )
            ),
          ]
        : undefined;

      const filterFreela = {
        certificates: val.certificates ? val.certificates : [],
        workGroups: val.workGroups ? val.workGroups : [],
        genders: val.genders ? val.genders : [],
        hasOwnTransport: val.hasOwnTransport ? val.hasOwnTransport : undefined,
        hasWorkMaterial: val.hasWorkMaterial ? val.hasWorkMaterial : undefined,
        hasWorkPictures: val.hasWorkPictures ? val.hasWorkPictures : undefined,
        hasFixedWork: val.hasFixedWork ? val.hasFixedWork : undefined,
        hasProfessionalAttire: val.hasProfessionalAttire,
        maximumToPay: val.maximumToPay ? val.maximumToPay : undefined,
        minAge: val.minAge ? val.minAge : undefined,
        maxAge: val.maxAge ? val.maxAge : undefined,
        latitude:
          this.hasLocalization && val.latitude ? val.latitude : undefined,
        longitude:
          this.hasLocalization && val.longitude ? val.longitude : undefined,
        eventDate,
      };

      this.getFreelaCount(filterFreela);
    },
    siblingsInputFocus() {
      this.siblingsInputOnFocus = true;
    },
    siblingsInputBlur() {
      this.siblingsInputOnFocus = false;
    },
    getWorkgroupOptions() {
      axios
        .get("/agencies/workgroups")
        .then(({ data }) => data)
        .then(({ result }) => {
          this.workGroupsOptions = result.content.map((x) => ({
            label: x.name,
            value: x.name,
          }));
        });
    },
    getPlaceLatLong() {
      axios
        .get("/events/location", {
          params: { placeId: this.formNewEvent.location.place_id },
        })
        .then(({ data }) => {
          this.filters.latitude = data.result.lat;
          this.filters.longitude = data.result.lng;
        });
    },
    getFreelaCount(filterFreela) {
      const hasFilters = qs.stringify(filterFreela, {
        arrayFormat: "repeat",
      });

      if (!hasFilters) {
        this.$emit("update-filters", {
          filterFreela: {
            hasProfessionalAttire: undefined,
            hasFixedWork: undefined,
            hasOwnTransport: undefined,
            hasWorkMaterial: undefined,
            hasWorkPictures: undefined,
            maximumToPay: null,
            minAge: null,
            maxAge: null,
            latitude: 0,
            longitude: 0,
            eventDate: null,
            genders: [],
            certificates: [],
            workGroups: [],
          },
          invitesToFreelas: [],
        });

        this.filteredCount = undefined;
        return;
      }

      axios
        .get("/vacancies/freela", {
          params: { ...filterFreela, service: this.$props.title },
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        })
        .then(({ data }) => {
          this.filteredCount = data.result.value.total;

          this.$emit("update-filters", {
            filterFreela: filterFreela,
            invitesToFreelas: data.result.value.freelaId,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-background-icon {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  margin: 14px;
  display: flex;
  align-items: center;
  background-color: #928ea4;

  &.red {
    background-color: #f13567;
  }
  &.blue {
    background-color: #46c5f3;
  }
  &.yellow {
    background-color: #ff961f;
    .status-icon {
      margin-top: 28px;
    }
  }

  .status-icon {
    font-size: 40px;
    height: 54px;
    width: 54px;
    top: 0px;
    margin-left: 7px;
    margin-top: 34px;
    font-weight: 300;
  }
}

.message {
  display: flex;
  align-items: center;

  .message-body {
    height: fit-content;
  }
  .description {
    font-size: 12px;
    font-weight: 300;
  }
}
</style>

<style lang="scss">
@import "@/styles/colors.scss";

.from-to-input {
  font-size: 11px;
  font-weight: 300;
  border-top: 2px groove white;
  border-bottom: 2px groove white;
  height: 50px;
  margin-top: 0px;
  z-index: 2;
  width: 50px;
  margin: 0 auto;
  align-self: center;
  position: absolute;
  background: #24203b;
  left: 0;
  right: 0;
  top: 22px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: border 0.25s;
}

.siblings-input {
  input {
    border-color: #7541bf !important;
  }

  .from-to-input {
    border-color: #7541bf !important;
  }
}

.text-align-center {
  input {
    text-align: center;
  }
}

.switch-title {
  font-size: 12px;
  font-weight: 300;
}

.result-card {
  width: 100%;
  height: 80px;
  display: flex;
  border-radius: 10px;
  background-color: #403a60;

  position: absolute;
  z-index: 10;
  bottom: 5px;
}

.mvh {
  height: 547px;
}

.bg-result {
  width: 93%;
  height: 100px;
  border: none;
  background-color: $SteelGray;

  position: absolute;
  z-index: 9;
  bottom: 0px;
}
</style>
