<template>
  <Modal :name="name" @open="onModalOpen" :title="title" ref="modal">
    <section class="w-100 d-flex direction-column jc-center p-10">
      <span>Digite o valor das vagas para candidatura</span>
      <CustomInput v-model="payment" type="currency" />
    </section>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <SpinnerButton
          :loading="loading"
          :disabled="disabled"
          class="btn primary"
          @click="publish">
          Publicar vagas
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import { strCurrencyToDecimal } from "@/helpers/form/currencyConvert";

export default {
  data: () => ({
    name: "vacancy-value-modal",
    title: "Publicar vagas",
    slots: [],
    eventId: "",
    payment: 0,
    loading: false,
    callback: () => ({}),
  }),
  computed: {
    disabled() {
      return this.payment === 0;
    },
  },
  methods: {
    ...mapActions({
      publishVacancies: "EventDetails/publishVacancies",
    }),
    publish() {
      const notifyResults = () => {
        this.notify(`Vagas publicadas`, "success");
        this.close();
      };

      const notifyError = (errors) =>
        this.notify(`${errors.response.data.errorMessage}`);

      this.publishVacancies({
        value: strCurrencyToDecimal(this.payment),
        eventId: this.eventId,
        slots: this.slots,
      })
        .then(notifyResults)
        .catch(notifyError);
    },

    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", { msg, type });
    },

    onModalOpen($event) {
      const { title, slots, eventId } = $event;
      this.loading = false;

      this.eventId = eventId;
      this.title = title;
      this.slots = slots;
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
</style>
