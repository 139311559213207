<template>
  <Modal
    :name="name"
    @open="onModalOpen"
    :title="isAgency ? 'Avaliar Contrante' : 'Avaliar Empresa'"
    ref="modal"
    :size="'larger'">
    <section class="d-flex f-100 wrapped jc-center pt-10">
      <article class="d-flex f-100 wrapped jc-center">
        <div class="d-flex f-100 ai-center direction-column pb-15">
          <img class="avatar radius-50" :src="image || imageUser" alt />
          <span class="font-size-06 mb-10">
            {{ (isAgency && rating.name) || nameAgency }}
          </span>
        </div>
      </article>
      <article class="d-flex f-50 star wrapped">
        <article class="d-flex f-100 wrapped mb-15">
          <span class="d-flex f-100 font-size-06">Gestão*</span>
          <div class="d-flex f-80 star-wrapper">
            <star-rating
              class="d-flex f-100 jc-space-evenly ai-center"
              v-model="management"
              :show-rating="false"
              :star-size="25"
              :rounded-corners="true"
              :padding="10"
              active-color="#FFCC00"></star-rating>
          </div>
        </article>
        <template v-if="isAgency">
          <article class="d-flex f-100 wrapped mb-15">
            <span class="d-flex f-100 font-size-06">Alimentação*</span>
            <div class="d-flex f-80 star-wrapper">
              <star-rating
                class="d-flex f-100 jc-space-evenly"
                v-model="food"
                :show-rating="false"
                :star-size="25"
                :rounded-corners="true"
                :padding="10"
                active-color="#FFCC00"></star-rating>
            </div>
          </article>
          <article class="d-flex f-100 wrapped mb-15">
            <span class="d-flex f-100 font-size-06">Estrutura*</span>
            <div class="d-flex f-80 star-wrapper">
              <star-rating
                class="d-flex f-100 jc-space-evenly"
                v-model="structure"
                :show-rating="false"
                :star-size="25"
                :rounded-corners="true"
                :padding="10"
                active-color="#FFCC00"></star-rating>
            </div>
          </article>
        </template>
        <template v-else>
          <article class="d-flex f-100 wrapped mb-15">
            <span class="d-flex f-100 font-size-06">Suporte*</span>
            <div class="d-flex f-80 star-wrapper">
              <star-rating
                class="d-flex f-100 jc-space-evenly"
                v-model="support"
                :show-rating="false"
                :star-size="25"
                :rounded-corners="true"
                :padding="10"
                active-color="#FFCC00"></star-rating>
            </div>
          </article>
        </template>
      </article>
      <article class="d-flex f-40 wrapped">
        <span class="font-size-1 mb-10">Comentário</span>
        <CustomInput
          v-model="title"
          class="d-flex f-100 pb-5"
          placeholder="Titulo" />
        <div class="d-flex f-100 wrapped">
          <span class="d-flex f-100 comment pb-5">Comentário</span>
          <textarea
            v-model="comments"
            class="d-flex f-100 area radius-5"
            rows="5"
            cols="38"></textarea>
        </div>
        <div class="d-flex f-100 mt-20 pt-20 jc-center">
          <button class="btn outline" @click="close">Cancelar</button>
          <button class="btn primary" @click="Rating">Salvar</button>
        </div>
      </article>
    </section>
    <section slot="footer" class="d-flex f-100"></section>
  </Modal>
</template>

<script>
import { agenciesRating, hirersRating } from "@/services/rating/rating.http";
import { mapGetters } from "vuex";
export default {
  components: {},
  data: () => ({
    agencyId: "",
    nameAgency: "",
    name: "avaliationModal",
    management: 0,
    support: 0,
    food: 0,
    structure: 0,
    comments: "",
    title: "",
    nameUser: "",
    image: "",
    ratingNumber: {
      1: "OneStar",
      2: "TwoStars",
      3: "ThreeStars",
      4: "FourStars",
      5: "FiveStarts",
    },
  }),
  computed: {
    ...mapGetters({
      rating: "EventDetails/rating",
      isAgency: "User/isAgency",
    }),
    imageUser() {
      return require("@/assets/icon_avatar.svg");
    },
  },
  methods: {
    onModalOpen({ agencyId, name }) {
      (this.agencyId = agencyId), (this.nameAgency = name);
    },
    Rating() {
      const { hirerId } = this.rating;
      const agenciesRatingBody = {
        id: this.agencyId,
        agencyId: this.agencyId,
        hirerId,
        ratings: [
          {
            rating: this.ratingNumber[this.management],
            skill: "Management",
            title: this.title,
            comment: this.comments,
          },
          {
            rating: this.ratingNumber[this.support],
            skill: "Support",
            title: this.title,
            comment: this.comments,
          },
        ],
      };
      const hirerRatingBody = {
        id: hirerId,
        agencyId: this.agencyId,
        hirerId,
        ratings: [
          {
            rating: this.ratingNumber[this.management],
            skill: "Management",
            title: this.title,
            comment: this.comments,
          },
          {
            rating: this.ratingNumber[this.food],
            skill: "Food",
            title: this.title,
            comment: this.comments,
          },
          {
            rating: this.ratingNumber[this.structure],
            skill: "Structure",
            title: this.title,
            comment: this.comments,
          },
        ],
      };
      this.isAgency
        ? this.hirersRatingSave(hirerRatingBody)
        : this.agenciesRatingSave(agenciesRatingBody);
    },
    clearModalOptions() {
      this.management = 0;
      this.support = 0;
      this.food = 0;
      this.structure = 0;
      this.title = "";
      this.comments = "";
    },
    agenciesRatingSave(body) {
      this.management === 0 || this.support === 0
        ? this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: 'Preencha todos os campos obrigatórios! "*"',
          })
        : agenciesRating(body)
            .then(() => {
              this.$eventhub.$emit("feedback:show", {
                type: "success",
                msg: "Avaliação Finalizada!",
              });
              this.clearModalOptions();
              this.close();
            })
            .catch(({ response }) => {
              this.$eventhub.$emit("feedback:show", {
                type: "error",
                msg: `${response.data.errorMessage}`,
              });
            });
    },
    hirersRatingSave(body) {
      this.management === 0 || this.food === 0 || this.structure === 0
        ? this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: 'Preencha todos os campos obrigatórios! "*"',
          })
        : hirersRating(body)
            .then(() => {
              this.$eventhub.$emit("feedback:show", {
                type: "success",
                msg: "Avaliação Finalizada!",
              });
              this.clearModalOptions();
              this.close();
            })
            .catch(({ response }) => {
              this.$eventhub.$emit("feedback:show", {
                type: "error",
                msg: `${response.data.errorMessage}`,
              });
            });
    },
    close() {
      this.clearModalOptions();
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.star {
  width: 35%;
  height: 40px;
  background: $Mirage;
  padding-bottom: 18px;
  padding-left: 5px;

  .star-wrapper {
    background: $Martinique;
    border-radius: 20px;
    padding-bottom: 1px;
    padding-top: 5px;
  }
}
.area {
  background: $Ebony;
}
.comment {
  font-size: 12px;
}
textarea {
  border: 2px solid;
  color: white;
}
textarea:focus {
  outline: none !important;
  color: white;
  border: 0.1rem solid;
  border-color: $FuchsiaBlue;
}
.avatar {
  position: relative;
  height: 60px;
  object-fit: cover;
  border: 2px solid grey;
  width: 60px;
  min-width: 60px;
}
</style>
