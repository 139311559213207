<template>
  <Modal
    class="faq-modal"
    size="larger"
    :name="name"
    @open="onModalOpen"
    title="Perguntas Frequentes"
    ref="modal">
    <template>
      <ul class="faq-list p-15">
        <li v-for="(question, k) in questions" :key="k">
          <md-checkbox
            :model="selected"
            checked
            :value="question"
            class="faq-check-item m-8">
            {{ question }}
          </md-checkbox>
        </li>
      </ul>
    </template>

    <template #footer>
      <div class="d-flex f-1 jc-end ai-end wrapped footer p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <button class="btn primary ml-15" @click="addFaqItems(selected)">
          Adicionar
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  data: () => ({
    name: "faq",
    faq: [],
    selected: [],
    onAdd: () => ({}),
  }),
  computed: {
    questions() {
      return this.faq.map(({ question }) => question);
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },

    addFaqItems(selected) {
      this.onAdd(selected);
      this.close();
    },

    onModalOpen($event) {
      const { faq, selected, onAdd } = $event;
      this.selected = selected;
      this.faq = faq;
      this.onAdd = onAdd;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
.faq-modal {
  .faq-list {
    margin-top: 2px;
    width: 100%;

    .faq-check-item.md-checkbox {
      .md-checkbox-label {
        height: auto;
        white-space: initial;
        font-size: initial;
      }
    }
  }
}
</style>
