<template>
  <Modal
    name="members"
    @open="onOpenModal"
    title="Membros"
    size="small"
    ref="modal">
    <section>
      <SearchMember @selected="onSelected"></SearchMember>
    </section>
  </Modal>
</template>
<script>
import SearchMember from "@/components/shared/SearchMember.vue";
import { mapActions } from "vuex";
export default {
  components: {
    SearchMember,
  },
  data: () => ({
    onSelectedMember: () => ({}),
  }),
  methods: {
    ...mapActions({ setSelectedMember: "EventDetails/setSelectedMember" }),

    onSelected(member) {
      this.onSelectedMember(member);
      this.$refs.modal.close();
    },
    onOpenModal({ onSelectedMember }) {
      this.onSelectedMember = onSelectedMember;
    },
  },
};
</script>
