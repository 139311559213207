<template>
  <Modal
    class="promotion-code-modal"
    size="small"
    :name="name"
    title="Adicionar cupom"
    ref="modal">
    <template>
      <CustomInput
        placeholder="Possui cupom?"
        label="Escreva aqui..."
        type="text"
        class="pl-20 pr-20 pt-10 upercase"
        :value="cupom"
        @input="cupom = $event.toUpperCase()" />
    </template>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button :disabled="!cupom" class="btn red" @click.prevent="removeCupom">
          Remover
        </button>
        <button class="btn primary" @click.prevent="addCupom">Adicionar</button>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  data: () => ({
    name: "cupom",
    cupom: "",
  }),

  methods: {
    addCupom() {
      this.$store.dispatch("NewEvent/setFormData", {
        promotionalCode: this.cupom,
      });
      this.close();
    },
    removeCupom() {
      this.cupom = "";
      this.$store.dispatch("NewEvent/setFormData", {
        promotionalCode: this.cupom,
      });
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
  mounted() {
    this.cupom = this.$store.getters["NewEvent/promotionalCode"];
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.promotion-code-modal {
  .upercase {
    text-transform: uppercase;
  }
}
</style>
