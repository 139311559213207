<template>
  <div class="w-80">
    <h3>{{ title }}</h3>
    <section class="">
      <input
        :class="{ 'list-empty': todos.length === 0 }"
        class=""
        type="text"
        :id="id"
        ref="input"
        :placeholder="placeholder"
        @keyup.enter.prevent="addItem" />
      <div class="list" v-if="todos.length">
        <div class="row" v-for="(item, index) in todos" :key="index">
          {{ item }}
          <i
            class="mdi mdi-close-circle-outline cursor-pointer"
            @click.prevent="remove(index)"></i>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import randomId from "@/helpers/randomId";

export default {
  name: "Checklist",
  props: {
    placeholder: {
      type: String,
      default: "Escreva aqui sua lista",
    },
    title: String,
    list: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    todos: [],
    id: randomId(),
  }),
  methods: {
    addItem({ target: { value } }) {
      if (!value) return;
      this.todos.push(value);
      this.$refs.input.value = "";
      this.$emit("input", [...this.todos]);
    },
    remove(index) {
      this.todos = this.todos.filter((item, i) => i !== index);
      this.$emit("input", [...this.todos]);
    },
  },
  created() {
    if (this.list.length > 0) this.todos = [...this.list];
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
section {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  //   &:first-child {
  //     align-items: flex-start;
  //   }
}
input[type="text"] {
  color: white;
  border: 3px solid $FuchsiaBlue;
  background: rgba($Martinique, 0.5);
  height: 70px;
  font-size: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid $FuchsiaBlue;

  &::placeholder {
    color: white;
    opacity: 0.5;
  }

  &.list-empty {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom: 3px solid $FuchsiaBlue;
  }
}

.list {
  width: 100%;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  border-left: 3px solid $FuchsiaBlue;
  border-right: 3px solid $FuchsiaBlue;
  border-bottom: 3px solid $FuchsiaBlue;

  list-style: none;
  margin: 0 0 0 0;
}

.row {
  word-wrap: break-word;
  position: relative;
  padding: 10px 3em 10px 10px;
  background: rgba($Martinique, 0.5);
  border-bottom: 1px solid $FuchsiaBlue;
  font-size: 20px;

  &:last-child {
    border-bottom: none;
  }

  & i {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

h3 {
  font-size: 20px;
  margin-top: 40px;
}
</style>
