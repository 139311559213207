<template>
  <ModalRight
    :name="nameModal"
    @open="onOpenModal"
    ref="modal"
    class="freela-profile-modal">
    <div>
      <div class="img-container">
        <img :src="freelaInfo.avatar" alt="avatar" class="img" />
        <div class="content-img">
          <div class="pl-5"></div>
          <div
            class="username font-size-1-3 text-white overflow-ellipsis title-img">
            {{ freelaInfo.name }}
          </div>
          <Chip :date="freelaInfo.jobs" size="small"></Chip>
        </div>
      </div>

      <div>
        <p class="font-size-07 text-white">
          {{ freelaInfo.description }}
        </p>
      </div>

      <div class="grid-container g-gap-10 gt-columns-4">
        <div
          class="grid-item d-flex direction-column jc-space-between"
          v-for="(item, key, index) in freelaInfo.card"
          :key="index">
          <span class="ml-5 mt-5">
            <i :class="item.icon" class="font-size-1-2"></i>
          </span>
          <span class="border-division font-size-06">{{ item.title }}</span>
          <span class="font-size-07 ml-5 overflow-ellipsis">
            {{ item.name }}
          </span>
        </div>
        <div
          class="grid-item d-flex direction-column"
          v-for="(item, key) in freelaInfo.cardAlternative"
          :key="key">
          <span class="border-division font-size-06">{{ item.title }}</span>
          <span class="font-size-08 ml-5" v-if="item.checked">
            SIM
            <i class="mdi mdi-check font-size-1-2 un-checked-color" />
          </span>
          <span class="font-size-08 ml-5" v-else>
            NÃO
            <i class="mdi mdi-close font-size-1-2 checked-color" />
          </span>
        </div>
      </div>

      <div class="d-flex jc-center mt-20 pb-10">
        <span
          :class="!show && 'btn-border'"
          class="cursor-pointer"
          @click="showContent(false)">
          Mais sobre mim
        </span>
      </div>

      <div class="card" :class="show && 'is-flipped'">
        <div class="card__face pr-20 pl-20">
          <CustomCard
            :divider="false"
            title="Contato"
            theme="ebony"
            class="mb-10 radius-10">
            <div class="d-flex direction-column pl-15 pb-10">
              <span>
                <i class="mdi mdi-email pr-5" />
                {{ freelaInfo.email }}
              </span>
              <span>
                <i class="mdi mdi-phone pr-5" />
                {{ freelaInfo.phone }}
              </span>
            </div>
          </CustomCard>

          <CustomCard
            :divider="false"
            title="Empresas que trabalho"
            theme="ebony"
            class="mb-10 radius-10">
            <div class="grid-container card-agencies pb-10 pl-10">
              <div
                class="d-flex direction-column ai-center w-80"
                v-for="(item, key) in agencies"
                :key="key">
                <Avatar class="img-agencies" />
                <span class="font-size-06 text-center">{{ item.name }}</span>
              </div>
            </div>
          </CustomCard>

          <CustomCard
            :divider="false"
            title="Habilidades"
            theme="ebony"
            class="mb-10 radius-10">
            <Chip
              v-if="freelaInfo.skill"
              :date="freelaInfo.skill"
              theme="Picton-blue"
              class="pl-15 pb-10" />
            <div v-else class="title-empty">
              <span>Nenhuma Habilidade</span>
            </div>
          </CustomCard>

          <CustomCard
            :divider="false"
            title="Disponibilidades"
            theme="ebony"
            class="mb-10 radius-10">
            <div
              class="d-flex direction-column"
              v-for="(item, key) in freelaInfo.availability"
              :key="key">
              <div class="d-flex jc-space-between pl-15 pr-15 pb-10">
                <span class="font-size-08">
                  {{ freelaInfo.daysOfWeek[item.dayOfWeek] }}
                </span>
                <span class="font-size-08 has-job" v-if="item.available">
                  {{ item.start | hour }} até {{ item.end | hour }}
                </span>
                <span class="font-size-08 without-job" v-else>
                  Não aceito job
                </span>
              </div>
            </div>
          </CustomCard>

          <CustomCard
            :divider="false"
            title="Galeria"
            theme="ebony"
            class="mb-10 radius-10">
            <div>
              <LightBox
                v-if="freelaInfo.gallery.length"
                :images="freelaInfo.gallery"
                :thumbnail="freelaInfo.gallery" />
              <div v-else class="title-empty">
                <span>Nenhuma foto</span>
              </div>
            </div>
          </CustomCard>

          <CustomCard
            :divider="false"
            title="Informações Bancárias"
            theme="ebony"
            class="mb-10 radius-10">
            <div
              class="d-flex jc-center direction-column pl-15 pr-15 pb-10"
              v-for="(item, index) in bankInfo"
              :key="index">
              <span class="font-size-09 pb-10 text-PictonBlue">
                {{ item.name }}
              </span>
              <span
                class="font-size-08"
                v-if="item.type !== '' && item.type !== null">
                {{ item.type }}
              </span>
              <span v-else>- - -</span>
            </div>

            <div class="custom-card">
              <header class="p-15 ai-center jc-space-between header">
                <h6>PIX</h6>
              </header>
              <div
                class="d-flex jc-center direction-column pl-15 pr-15 pb-10"
                v-for="(item, index) in bankInfoWithPIX"
                :key="index">
                <span class="font-size-09 pb-10 text-PictonBlue">
                  {{ item.name }}
                </span>
                <span
                  class="font-size-08"
                  v-if="item.type !== '' && item.type !== null">
                  {{ item.type }}
                </span>
                <span v-else>- - -</span>
              </div>
            </div>
          </CustomCard>
        </div>
      </div>
    </div>
  </ModalRight>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FreelaProfileModal",
  data: () => ({
    informations: {},
    show: false,
    nameModal: "freela-profile-modal",
    skill: [],
    availability: [],
  }),
  filters: {
    hour(value) {
      return value.slice(0, 5);
    },
  },
  computed: {
    ...mapGetters({
      freelaInfo: "Freela/freelaInfo",
      agencies: "Freela/agencies",
      userId: "User/userId",
      isFreela: "User/isFreela",
    }),
    bankInfo() {
      const {
        bankAccountType,
        cpf,
        cnpj,
        bankAccount,
        bankBranch,
        bankCode,
        owner,
        rgNumber,
      } = this.freelaInfo;
      return [
        { name: "Nome do Titular", type: owner },
        {
          name: "CPF/CNPJ",
          type: cpf === null ? cnpj : cpf,
        },
        { name: "RG", type: rgNumber },
        { name: "Tipo", type: bankAccountType },
        { name: "Banco", type: bankCode },
        { name: "Agência", type: bankBranch },
        { name: "Conta", type: bankAccount },
      ];
    },
    bankInfoWithPIX() {
      const PIX = [
        { name: "Tipo de chave PIX", type: this.freelaInfo.pixType },
        { name: "Chave PIX", type: this.freelaInfo.pixKey },
      ];

      return PIX;
    },
  },
  created() {
    this.viewProfile();
  },
  methods: {
    ...mapActions({
      getFreelaInformations: "Freela/getFreelaInformations",
    }),
    showContent(event) {
      this.show = event;
    },
    onOpenModal() {},
    viewProfile() {
      if (this.$route.name === "view-profile") {
        const access = this.$route.query.access;
        this.$store.dispatch("User/userLogged", access);
        if (this.isFreela) {
          this.openModal = true;
          this.getFreelaInformations(this.userId);
        } else {
          this.close();
          this.$store.dispatch("User/logout");
          this.$router.push("/acesso/logout");
        }
      }
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.card {
  width: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  position: relative;
}

.card.is-flipped {
  transform: rotateY(180deg);
}

.card__face {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.img-container {
  position: relative;
}

.img-agencies {
  position: initial;
}

.img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.content-img {
  position: absolute;
  top: 74%;
  left: 10px;
}

.title-img {
  width: 15em;
  margin: 10px 0px;
}
.title-empty {
  padding: 20px;
  margin-top: -15px;
  color: rgba(255, 255, 255, 0.8);
}

.chip {
  padding: 0px 7px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 6px;
  background-color: $LilacBush;
}

.card-agencies {
  -ms-grid-columns: repeat(auto-fit, 100px);
  grid-template-columns: repeat(auto-fit, 100px);
  grid-gap: 5px;
}

.grid-container {
  display: -ms-grid;
  display: grid;
  &.g-gap-10 {
    grid-gap: 10px;
  }
  &.gt-columns-4 {
    grid-template-columns: repeat(4, auto);
    -ms-grid-columns: repeat(4, auto);
  }
  &.gt-columns-3 {
    grid-template-columns: repeat(3, auto);
    -ms-grid-columns: repeat(4, auto);
  }
}

.grid-item {
  background-color: $Ebony;
  height: 82px;
  width: 78px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.border-division {
  border-bottom: 2px solid $LilacBush;
  margin: 6px;
}

.btn-border {
  display: inline-block;
  text-align: center;
  padding-bottom: 5px;
  background: linear-gradient(
    90deg,
    transparent 15%,
    #eb4886 5%,
    #eb4886 85%,
    transparent 72%
  );
  background-size: 100% 2px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
}

.without-job {
  color: #eb4886;
  letter-spacing: 0.7px;
}

.has-job {
  color: $PictonBlue;
}
.checked-color {
  color: $Red;
}
.un-checked-color {
  color: $RobinsEggBlue;
}
</style>

<style lang="scss">
.freela-profile-modal .custom-card > header h6 {
  font-size: 1.4rem !important;
}
</style>
