<template>
  <Modal
    name="emergencialVacancy"
    @open="onOpenModal"
    title="vaga URGENTE"
    size="small"
    ref="modal"
    titleColor="color-red">
    <section class="d-flex f-100 wrapped jc-center">
      <CustomSelect
        placeholder="Para qual serviço"
        class="d-flex f-92"
        v-model="currentService">
        <option v-if="currentService === ''">-</option>
        <option
          v-for="({ job }, key) in services"
          :key="key"
          :selected="job === currentService"
          :value="job">
          {{ job }}
        </option>
      </CustomSelect>
      <CustomInput
        placeholder="Valor da vaga"
        class="d-flex f-92"
        type="currency"
        v-model="payment" />
      <v-date-picker
        v-model="checkin"
        mode="dateTime"
        is24hr
        is-dark
        is-inline
        :popover="{
          placement: $screens({ sm: 'auto' }),
          visibility: 'click',
        }"
        :update-on-input="false"
        :available-dates="{ start: new Date(), end: null }"
        class="d-flex f-45">
        <template v-slot="{ inputValue, inputEvents }">
          <CustomInput
            placeholder="Horário do início"
            :value="inputValue"
            v-on="inputEvents"></CustomInput>
        </template>
      </v-date-picker>
      <v-date-picker
        v-model="checkout"
        mode="dateTime"
        is24hr
        is-dark
        is-inline
        :popover="{
          placement: $screens({ sm: 'auto' }),
          visibility: 'click',
        }"
        :update-on-input="false"
        :available-dates="{ start: new Date(), end: null }"
        class="d-flex f-45">
        <template v-slot="{ inputValue, inputEvents }">
          <CustomInput
            placeholder="Horário do término"
            :value="inputValue"
            v-on="inputEvents"></CustomInput>
        </template>
      </v-date-picker>
    </section>
    <footer>
      <article class="d-flex f-100 jc-center mt-50">
        <button
          class="btn primary d-flex f-50 jc-center"
          @click.prevent="onCreate">
          Adicionar
        </button>
      </article>
    </footer>
  </Modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { strCurrencyToDecimal } from "@/helpers/form/currencyConvert";
export default {
  name: "emergencialVacancyModal",

  data: () => ({
    currentService: "",
    payment: "",
    checkin: "",
    checkout: "",
  }),
  computed: {
    ...mapGetters({ operationService: "Dashboard/operationService" }),

    services() {
      return this.operationService.services;
    },
  },
  methods: {
    ...mapActions({
      getServices: "Dashboard/getOperationServices",
      createEmergencialVacancy: "Dashboard/createEmergencialVacancy",
    }),

    onOpenModal({ operationId }) {
      this.getServices(operationId);
    },
    onCreate() {
      const { eventId, day } = this.operationService;
      const body = {
        eventId,
        day,
        quantity: 1,
        payment: strCurrencyToDecimal(this.payment),
        job: this.currentService,
        checkin: this.checkin,
        checkout: this.checkout,
      };

      this.createEmergencialVacancy(body)
        .then(this.feedbackSuccess("Vaga emergencial criada com sucesso"))
        .then(this.closeModal)
        .catch(this.feedbackError);
    },

    closeModal() {
      this.currentService = "";
      this.payment = "";
      this.checkin = "";
      this.checkout = "";
      this.$refs.modal.close();
    },

    feedbackError({ message }) {
      this.$eventhub.$emit("feedback:show", {
        type: "error",
        msg: `${message}`,
      });
    },

    feedbackSuccess(message) {
      this.$eventhub.$emit("feedback:show", {
        type: "success",
        msg: `${message}`,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors";
</style>
