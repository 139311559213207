<template>
  <Modal
    class="occurrece-link-modal"
    :name="name"
    @open="onModalOpen"
    title="Ocorrência"
    ref="modal">
    <template>
      <div class="body d-flex jc-center ai-center h-100 w-100">
        <figure
          class="occurrence-image d-flex jc-center ai-center direction-column">
          <img class="h-100 w-100" :src="occurrence.imageUrl" />
          <figcaption class="mt-10">
            {{ occurrence.message }}
          </figcaption>
        </figure>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  data: () => ({
    name: "occurrencelink",
    occurrence: {},
  }),
  methods: {
    close() {
      this.$refs.modal.close();
    },
    onModalOpen($event) {
      const occurrence = $event;
      this.occurrence = occurrence;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";

.occurrence-image {
  img {
    height: 300px !important;
    object-fit: contain;
    width: auto;
  }

  figcaption {
    font-size: 1rem;
  }
}
.btn-close {
  background: $Martinique;
  border-radius: 50px;
  color: white;
  width: 35px;
  height: 35px;
  font-size: 1.5rem;
}
</style>
