<template>
  <cropper
    class="crop-box"
    ref="cropper"
    :src="image"
    :stencil-props="{
      handlers: {},
    }"
    :stencil-size="{
      width: 500,
      height: 500,
    }"
    image-restriction="stencil"
    background-class="cropper-background" />
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "Croper",

  props: ["image"],

  components: {
    Cropper,
  },
};
</script>

<style>
.cropper-background {
  background: none;
}
.crop-box {
  width: 600px;
  height: 600px;
}
</style>
