<template>
  <section class="d-flex f-100 wrapped jc-center p-relative">
    <WorkshiftCard
      v-for="(date, index) in dates"
      :key="index"
      :date="date"
      :workshiftCardIndex="index"
      :service="service"
      @change="changeHandler"></WorkshiftCard>
  </section>
</template>
<script>
import WorkshiftCard from "./WorkshiftCard.vue";
export default {
  name: "WorkshiftList",
  components: { WorkshiftCard },
  props: {
    dates: {
      type: Array,
      default: () => {},
    },
    service: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    workshifts: [],
  }),
  methods: {
    changeHandler($event) {
      const date = new Date($event[0].date);
      this.workshifts = [
        ...this.workshifts.filter(
          (item) =>
            new Date(item.date).toLocaleDateString() !==
            date.toLocaleDateString()
        ),
        ...$event,
      ];

      this.$emit("change", this.workshifts);
    },
  },
};
</script>
