<template>
  <section class="payment w-100 d-flex">
    <div class="spacing-1">
      <CustomInput
        :isInvalid="$v.rawPayment.$error"
        type="currency"
        placeholder="Valor pago por dia ao profissional"
        example="Por exemplo: R$120,00"
        v-model.trim="payment" />
      <div class="error d-flex wrapped f-100">
        <span class="d-flex f-100" v-if="!$v.rawPayment.minValue">
          Valor mínimo: R$ {{ this.oldValue.replace(".", ",") }}
        </span>
      </div>
      <div v-show="isAgency">
        <CustomInput
          type="currency"
          placeholder="Lucro da agência"
          example="Por exemplo: R$20,00"
          v-model.trim="agencyProfit" />
      </div>
    </div>
    <div class="spacing-2">
      <PaymentResume :service="value" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { minValue } from "vuelidate/lib/validators";
import { strCurrencyToDecimal } from "@/helpers/form/currencyConvert";

import PaymentResume from "./PaymentResume.vue";

export default {
  components: {
    PaymentResume,
  },
  props: {
    value: Object,
  },

  data() {
    return {
      oldValue: this.value.payment,
      rawPayment: 0,
    };
  },

  validations() {
    return {
      rawPayment: {
        minValue: minValue(this.oldValue),
      },
    };
  },
  watch: {
    payment(newVal) {
      this.rawPayment = strCurrencyToDecimal(newVal);
    },
  },
  computed: {
    ...mapGetters({
      isAgency: "User/isAgency",
    }),
    payment: {
      get() {
        return this.value?.payment;
      },
      set(val) {
        this.$emit("input", { ...this.value, payment: val });
      },
    },
    agencyProfit: {
      get() {
        return this.value?.agencyProfit;
      },
      set(val) {
        this.$emit("input", { ...this.value, agencyProfit: val });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.spacing-1 {
  flex-grow: 1;
  max-width: 50%;
}
.spacing-2 {
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  align-items: center;
  justify-content: center;
}
</style>
